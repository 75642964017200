import xiaochengxu from '@/assets/images/home/xiaochengxu.jpg'
import siji from '@/assets/images/home/siji.png'
import huozhu from '@/assets/images/home/huozhu.png'
import beijing from '@/assets/images/about/lian_xi_wo_men-beijing@2x.png'
import anhui from '@/assets/images/about/lian_xi_wo_men-anhui@2x.png'
import shandong from '@/assets/images/about/lian_xi_wo_men-shandong@2x.png'
import productC3Img from '@/assets/images/product/body_content3.png'
import productC3EnImg from '@/assets/images/product/body_content3_en.png'
export const chinese = {
    "app": {
        "common": {
            "foot": {
                "footLeft": {
                    "one": "物流数智化一站式解决方案服务商",
                    "two": "24小时客服",
                    "three": "400-080-5656",
                    "four": "地址：北京市顺义区顺西南路2号院"
                },
                "footRightTextList": [
                    {"name":"产品中心","key":"product"},
                    {"name":"关于我们","key":"about"},
                    {"name":"联系我们","key":"about","tabName":"contactUs"}
                ],
                "copyRight_1": "北京星日朗供应链管理有限公司 Copyright © www.zhipig.com All rights reserved. ",
                "copyRight_2": "京ICP备2021020961号-1"
            },
            "news": {
                "title": "最新动态",
                "newsItemBtn": "点击查看",
                "moreNews": "更多动态"
            },
            "footerCodeList":[
                {"name":"智猪小程序","img":xiaochengxu},
                {"name":"智猪司机端","img":siji},
                {"name":"智猪货主端","img":huozhu}
            ],
            "jobAndNewDetailBtn":"返回"
        },
        "nav": {
            "language":"EN",
            "navList": [
                {"name":"首页","key":"home"},
                {"name":"产品中心","key":"product"},
                {"name":"关于我们","key":"about"},
                {"name":"APP下载","key":"download"}
            ]
        },
        "home": {
            "banner": {
                "centerText": "物流智能化一站式解决方案服务商",
                "bottomText": "鼠标滑动查看更多"
            },
            "bodyContent1": {
                "title": "智猪大数据",
                "rowOne":[
                    {
                        "name":'业务覆盖城市',
                        "num":0,
                        "unit":'+',
                        "width":'12rem'
                    },
                    {
                        "name":'业务覆盖路线',
                        "num":0,
                        "unit":'W+',
                        "width":'4rem'
                    },
                    {
                        "name":'累计运输里程',
                        "num":0,
                        "unit":'W',
                        "width":'22rem'
                    }
                ],
                "rowTwo":[
                    {
                        "name":'累计完成托运订单数',
                        "num":0,
                        "unit":'W+',
                        "width":'12rem'
                    },
                    {
                        "name":'累计用户数',
                        "num":0,
                        "unit":'W+',
                        "width":'8rem'
                    }
                ],
            },
            "bodyContent2": {
                "title": "物流智能化生态系统",
                "content": [
                    "依托互联网智能算法，打造数十万级运力资源池，精准解决货主用车需求，平台对过程进行有效管控，做到结算清分、资金安全、税务合规、操作简便，为广大用户提供高质量服务。",
                    "为企业提供精细化管理，让操作简单便捷，做到运单全流程可追溯，运输过程整体监控，实现风险可控，提供可视化报表、上下游结算账务管理、车队管理等服务；将车辆、GPS、配件等资产线上化，做到资产智能化管理为企业赋能。",
                    "为用户提供10000+座合作油站，实现油站的全国范围内覆盖，让司机加油更加便捷高效。为合作客户提供线上管理油品、管清油账、柴油加注等服务，解决企业的油卡圈存以及实体卡的管理难度问题，为物流企业或个体司机减少用油成本，降本增效。",
                    ["维修保养", "供应链金融", "保险服务", "尿素加注", "运力招采", "司机招聘", "灵活用工", "ETC服务"]
                ]
            },
            "bodyContent3": {
                "title": "合作伙伴"
            }
        },
        "product": {
            "bodyContent1": {
                "title": "星云网络货运服务",
                "des": "提供线上车货匹配服务，通过货源运力算法匹配+全方位用户画像，精准解决用车及找货的需求；平台做到资金安全、税务规范、操作简便，贴合国家政策，规划货运市场的信息流，轨迹流、票务流、合同流以及资金流。解决了客户开票问题和司机端合规风险与个税问题，全面完善了司机的单车清结算跟税务管理。",
                "bodyTitle":"算法链接 智能规划" ,
                "bodyFoot": [
                    {
                        "title": "线路规划法",
                        "content": "打通业务壁垒，利用平台链接货源，通过两点往返，三点循环等智能算法，排除异常因素形成最优路线，为企业降本，为司机提升效率。"
                    },
                    {
                        "title": "用户画像体系",
                        "content": "平台用户体系的搭建，盘活平台运力资源，通过个人体系不断充实以及更新用户画像数据，为用户召回，活动运营，车货匹配提供了强有力的支撑。"
                    },
                    {
                        "title": "智能推荐算法",
                        "content": "通过算法为货源匹配合适的司机，通过为司机推荐货源提升货源的成交率，相对于线下联系的单程沟通，大大提升了货源匹配司机的效率。"
                    }
                ]
            },
            "bodyContent2": {
                "title": "智猪智慧管理系统",
                "bodyLeft": {
                    "c1": "智能化系统SAAS级服务",
                    "c2": "满足多场景业务运营需求，清晰企业数据，释放人力资源，降低服务成本",
                    "c3": "通过搭建开放式SAAS化运输管理平台，打通供应链上下游的单据、物流和结算信息 帮助平台上的客户更加高效便捷的管理整体运输业务。",
                    "leftBottom": [
                        {
                            "title": "平台优势",
                            "content": "信息化与智能设备结合，支持GPS位置管理，车辆温控管理，危险路段预警，危险驾驶反馈等。"
                        },
                        {
                            "title": "数据优势",
                            "content": "数据信息化，让企业的生产工作更加高效透明，结合各模块的数据分析让企业的营收支出更加全面，更加清晰。"
                        }
                    ]
                },
                "bodyRight": {
                    "title": "车辆管理服务",
                    "content": "满足企业自有车的管理需求，实时掌握车辆位置、轨迹、油耗等实时数据，通过司机评分系统实时监控司机驾驶行为，降低安全隐患。",
                    "rightBottom":{
                        "tagOne": ["人员管理", "驾驶习惯", "行为监控", "实时报备", "司机画像", "司机招聘"],
                        "tagTwo": ["位置轨迹", "油耗管理", "任务关联", "违章及年审", "事故及违章", "保险及租赁"]
                    }
                }
            },
            "bodyContent3": {
                "title": "数字能源服务",
                "des": "为用户提供10000+座合作油站，实现油站的全国范围内覆盖，让司机加油更加便捷高效。为合作客户提供线上管理油品、管清油账、柴油加注等服务，解决企业的油卡圈存以及实体卡的管理难度问题，为物流企业或个体司机减少用油成本，降本增效。",
                "img": productC3Img
            },
            "bodyContent4": {
                "title": "增值服务",
                "des": "围绕货主和司机进行全场景生态化布局，通过技术创新和信息化系统的搭建，打通资源壁垒，在业务上为货主提供优质的承运服务，为司机提供优质的货源信息，在管理上结合算法和系统信息化为企业降本增效，同步集成供应链金融、尿素加注、保险、维修保养、ETC、司机招聘、运力招采、灵活用工等服务，用心服务好货主和司机。",
                "body": {
                    "left": [
                        {
                            "name": "维修保养",
                            "content": "智猪科技与全国8000+维修保养站点合作，线上记录车辆状态，提供维修保养预警；专人线下考察站点服务能力、专业维保技术、商家认证等信用，保障站点服务质量，降低司机使用风险和使用成本。"
                        },
                        {
                            "name": "供应链金融",
                            "content": "对接多家第三方金融渠道，为物流客户选择最合适的合作伙伴，通过对云数据链路的全面监管，并借助银行和平台共同搭建的信用体系，加大金融系统的干预力度，实现运输、金融等在线集成供应链服务，解决企业资金流问题，为企业收益创造更大的利润空间。"
                        },
                        {
                            "name": "保险服务",
                            "content": "根据大数据分析司机的行驶、加油、维保等行为习惯，帮助企业做智能测评，帮助司机做智能预警，降低运输过程事故率，减少保险的赔付率。"
                        },
                        {
                            "name": "尿素加注",
                            "content": "智猪科技与多个尿素品牌合作，在全国主要快递快运场站设立加注站点，为司机和车主提供线上支付、即用即走的尿素加注服务。"
                        },
                        {
                            "name": "运力招采",
                            "content": "货主可通过智猪平台发布招采信息，司机或车主可通过智猪平台参与竞标，通过大数据可以呈现各车队的真实运营情况，从而为货主推荐优质的车队和司机。"
                        },
                        {
                            "name": "司机招聘",
                            "content": "用户可在智猪平台发布招聘信息，为车主提供优质司机，保证车辆运行效率。司机可通过平台找到一份满意的工作，提升个体收入。"
                        },
                        {
                            "name": "灵活用工",
                            "content": "智猪科技通过平台为企业提供灵活用工服务，帮助企业与临时雇工从传统的劳动雇佣关系变成基于平台的价值合作与交换关系，使人力资源真正实现优化配置。"
                        },
                        {
                            "name": "ETC服务",
                            "content": "对接票根网ETC票务平台，完成运输后及时获取ETC发票，自动将ETC发票归类整合，节约物流公司线下对账等时间成本。"
                        }
                    ],
                    "right": {
                        "title": "增值产品",
                        "content": [
                            {"class": "icon2","nameFlag": "维修保养", "name": "维修保养", "index": 0},
                            {"class": "icon3","nameFlag":"灵活用工", "name": "灵活用工", "index": 6},
                            {"class": "icon4","nameFlag": "运力招采", "name": "运力招采", "index": 4},
                            {"class": "icon5","nameFlag":"尿素加注", "name": "尿素加注", "index": 3},
                            {"class": "icon6","nameFlag": "保险服务", "name": "保险服务", "index": 2},
                            {"class": "icon7","nameFlag":"ETC服务", "name": "ETC服务", "index": 7},
                            {"class": "icon8","nameFlag": "司机招聘", "name": "司机招聘", "index": 5},
                            {"class": "icon9","nameFlag":"供应链金融", "name": "供应链<br/>金融", "index": 1}
                        ]
                    }
                }
            }
        },
        "about": {
            "title": "为物流行业提供智能化一站式解决方案",
            "navList": [
                {"name":"企业简介","code":"companyProfile"},
                {"name":"发展历程","code":"developmentHistory"},
                {"name":"社会责任","code":"socialResponsibility"},
                {"name":"最新动态","code":"newTrends"},
                {"name":"人才招聘","code":"talentAcquisition"},
                {"name":"联系我们","code":"contactUs"}
            ],
            "companyProfile": {
                "title": "关于智猪科技",
                "content": "创建于2018年，注册资金5000万，是一家为中小物流企业提供智能化服务的互联网创新平台企业。在传统行业转型升级和互联网不断渗透的新时代背景下，对资源的高效整合和充分利用打造出“车货匹配”模式，为传统货运行业的发展开辟出崭新的道路。"
            },
            "developmentHistory": {
                "title": "发展历程",
                "timeLine": [
                    {"date": "2018", "des": "智猪成立"},
                    {"date": "2019", "des": "信息化阶段"},
                    {"date": "2021", "des": "多元化产品协同发展"},
                    {"date": "2022", "des": "智能化、数字化阶段"},
                    {"date": "", "des": "我们的故事还在继续……"}
                ]
            },
            "socialResponsibility": {
                "title": "社会责任",
                "des": ["促进生态环境保护，减少碳排放。通过大数据智能算法、先进GPS导航等技术为司机提供线路规划、提升有效运营里程，使车辆运输能源同比消耗量下降，减少碳排放。", "致力于道路运输安全。智猪科技采用主动安全设备、智能人脸识别系统等科技帮助司机避免疲劳驾驶导致安全事故发生和减少交通事故致死率，同时，保障运输的时效和货物的安全。", "促进运输行业健康发展、为个体司机提供收入来源。平台为传统物流企业降本增效，提升企业盈利能力。平台每年为20万司机提供运单撮合服务，减少车队中介等渠道费用，增加司机收入，促进社会稳定和谐发展。"]
            },
            "talentAcquisition": {
                "title": "准备好加入我们了吗?",
                "button": "查看职位",
                "body":[
                    {"type": "愿景", "des": "成为中国物流首选数智化一站式服务平台"},
                    {"type": "使命", "des": "科技赋能 为企业降本增效"},
                    {"type": "价值观", "des": "专业创新 有效赋能 诚实务实 合作共赢"}
                ],
                "jobs":{
                    "leftTitle":"招聘内容",
                    "showJobsTypeBtn":"查看类别",
                    "pickerTitle":"职位类别",
                    "pickerBtnText":["取消","确定"],
                    "jobsList": [
                        {
                            "type": '产品技术类',
                            "total":6,
                            "subList":["产品总监","JAVA开发工程师","WEB开发工程师","IOS开发工程师","Android开发工程师","测试工程师"],
                            "children": [
                                {
                                    "name":'产品总监',
                                    "salary": '2万-4万·13薪',
                                    "tag": '计算机相关专业/本科以上学历',
                                    "description":'1.熟悉物流、无车承运人、供应链市场相关政策、法规、具有市场敏感度和前瞻意识。',
                                    "descriptions": {
                                        "tips": ['物流', '网货', '供应链', '数据', '5年', '本科'],
                                        "duty":
                                            `1.熟悉物流、无车承运人、供应链市场相关政策、法规、具有市场敏感度和前瞻意识。<br/>` +
                                            `2.具备商业视角，能够从市场角度与营收角度对产品设计做深沉思考，具有一定的创新能力。<br/>` +
                                            `3.对交互设计、用户体验、界面等有较强的把控经验。<br/>`+
                                            `4.对物流行业的业务模式、特殊要求、未来趋势有深入理解与独特思考,具备良好的业务敏感度和视野,能够敏锐的捕获产品机会和数据价值。<br/>` +
                                            `5.良好的文档组织能力,良好的书面与口头表达能力，良好的人际沟通交流能力，良好的逻辑思维能力。<br/>` +
                                            '6.独立主导过至少1个以上平台级及企业级项目或产品。',
                                        "require":
                                            `1.计算机相关专业，本科以上学历。<br/>` +
                                            `2.受过战略管理、管理能力开发、项目管理、创新管理、领导力等方面的培训。<br/>` +
                                            '3.5年以上产品经理或产品运营工作经验，有福佑、运满满，G7，路歌及找油网等物流相关互联网平台经验优先。',
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name":'JAVA开发工程师',
                                    "salary": '1.5万-2.5万·13薪',
                                    "tag": '2年以上工作经验/专科以上学历',
                                    "description":'1.负责项目的需求分析、开发、日常维护工作，保证公司项目的正常进行。',
                                    "descriptions": {
                                        "tips": ['JAVA', 'J2EE', 'Spring', 'spring-boot', '后端开发', '2年',"专科"],
                                        "duty":
                                            `1.负责项目的需求分析、开发、日常维护工作，保证公司项目的正常进行。<br/>` +
                                            `2.参与软件产品项目规划工作，制定具体项目实施方案。<br/>` +
                                            `3.整合并优化项目开发所需各种资源。<br/>` +
                                            `4.负责软件开发技术和规范及标准流程的改进。<br/>` +
                                            `5.参与软件系统的设计和分析。<br/>` +
                                            '6.根据开发进度和任务分配，完成相应模块软件的设计、开发、编程任务。',
                                        "require":
                                            `1.计算机技术或电子信息以及电子工程等相关专业优先，专科以上学历。<br/>` +
                                            `2.2年以上工作经验，有运满满，G7，路歌、福佑及找油网等物流相关互联网平台经验者优先。<br/>` +
                                            `3.2年以上工作经验，有运满满，G7，路歌、福佑及找油网等物流相关互联网平台经验者优先。<br/>` +
                                            `4.事业心强，勤奋好学，有团队精神。<br/>` +
                                            `5.扎实的JAVA语言基础。熟悉J2EE整体结构、熟练使用Spring 、spring-boot等开源框架， 熟悉JavaScript脚本语言。了解HTTP协议、json、nginx等。<br/>` +
                                            '6.掌握简单linux命令。',
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name": 'WEB开发工程师',
                                    "salary": '1.5万-2.5万·13薪',
                                    "tag": '3年以上工作经验/本科以上学历',
                                    "description":'1.负责产品前端的实现，包含PC、移动Web、微信小程序等',
                                    "descriptions": {
                                        "tips": ['Web前端', 'H5小程序', 'JavaScript', '3年', '本科'],
                                        "duty":
                                            `1.负责产品前端的实现，包含PC、移动Web、微信小程序等<br/>` +
                                            `2.与产品经理合作，深度参与产品需求讨论，功能定义等<br/>` +
                                            '3.设计良好的代码结构，不断迭代重构。',
                                        "require":
                                            `1.本科及以上学历，计算机相关专业。<br/>` +
                                            `2.至少3以上Web前端或H5小程序研发经验，4年以上经验优先。<br/>` +
                                            `3.掌握 JavaScript 语言、ES6 规范、通信协议、网络等前端知识。<br/>` +
                                            `4.热爱前端技术，有强烈的好奇心和求知欲，有良好的编码规范，对各种Web前端技术有深刻理解。<br/>` +
                                            `5.关注Web发展，对新技术充满激情，期待或者已经开发出优秀的产品。<br/>` +
                                            `6.熟悉服务器端Web应用结构。<br/>` +
                                            `7.工作认真、态度严谨、责任心强。对自身技术的提升和软件的质量和性能有高的要求。<br/>` +
                                            `8.具有良好的分析问题和解决问题的能力。<br/>` +
                                            `9.具备良好的沟通能力和优秀的团队协作能力。<br/>` +
                                            '10.有知名开源项目者优先。有大型项目架构经验者优先。',
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name": 'IOS开发工程师',
                                    "salary": '1.5万-2.5万·13薪',
                                    "tag": '3年以上工作经验/本科以上学历',
                                    "description":'1.参与产品设计：根据业务需求，积极参与产品的讨论、定义、设计等工作',
                                    "descriptions": {
                                        "tips": ['IOS', 'Objective-C', 'SDK', '应用开发', '3年',"本科"],
                                        "duty":
                                            `1.参与产品设计：根据业务需求，积极参与产品的讨论、定义、设计等工作;<br/>` +
                                            `2.技术文档编写：按照公司软件开发管理规范要求，根据上级分配的任务，编制软件开发详细设计文档，保证开发过程的透明度与可控性;<br/>` +
                                            `3.软件编码：根据开发文档，进行重要子系统/模块的软件编码工作，并根据系统中具体的难点问题，参与针对具体技术难点的技术攻关，按阶段有计划地完成代码开发计划，确保项目的高质量完成;<br/>` +
                                            `4.产品测试：根据公司产品测试相关流程及产品特点，配合测试部门和业务部门进行产品测试和验收工作，组织软件系统的安装调试，保证产品顺利发布;<br/>` +
                                            '5.质量活动：参与软件模块的代码走读、代码正规检视、设计文档正规检视、设计评审等质量保证活动，降低软件产品缺陷。' ,
                                        "require":
                                            `1.本科及以上学历，3年以上iOS开发经验<br/>` +
                                            `2.精通Objective-C语言，熟悉操作系统内存管理和代码编译的原理，拥有良好的代码规范<br/>` +
                                            `3.熟练掌握iOS的开发框架，熟悉常见的三方开源库使用<br/>` +
                                            `4.熟练使用常见的设计模式，能够根据不同场景采用合理的设计模式<br/>` +
                                            `5.有上线过iOS产品，有产品升级迭代经验<br/>` +
                                            '6.具有良好的沟通协调能力，能接受比较高强度的抗压能力；良好的文档撰写能力',
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name": 'Android开发工程师',
                                    "salary": '1.5万-2.5万·13薪',
                                    "tag": '3年以上工作经验/专科以上学历',
                                    "description":'1.参与产品设计：根据业务需求，积极参与产品的讨论、定义、设计等工作;',
                                    "descriptions": {
                                        "tips": ['Android', 'SDK', '应用开发', '3年',"本科"],
                                        "duty":
                                            `1.参与产品设计：根据业务需求，积极参与产品的讨论、定义、设计等工作;<br/>` +
                                            `2.技术文档编写：按照公司软件开发管理规范要求，根据上级分配的任务，编制软件开发详细设计文档，保证开发过程的透明度与可控性;<br/>` +
                                            `3.软件编码：根据开发文档，进行重要子系统/模块的软件编码工作，并根据系统中具体的难点问题，参与针对具体技术难点的技术攻关，按阶段有计划地完成代码开发计划，确保项目的高质量完成;<br/>`+
                                            `4.产品测试：根据公司产品测试相关流程及产品特点，配合测试部门和业务部门进行产品测试和验收工作，组织软件系统的安装调试，保证产品顺利发布;<br/>` +
                                            '5.质量活动：参与软件模块的代码走读、代码正规检视、设计文档正规检视、设计评审等质量保证活动，降低软件产品缺陷。' ,
                                        "require":
                                            `1.大专及以上学历，3年以上安卓开发经验，具有有独立开发经验优先<br/>` +
                                            `2.熟悉Android系统体系结构、framework、底层库，以及UI设计<br/>` +
                                            `3.熟悉Android开发工具，能够熟练使用Android SDK<br/>` +
                                            `4.熟悉Android下网络通信机制，对Socket通信、TCPIP、Http有一定理解和经验<br/>` +
                                            '5.具有良好的沟通协调能力，能接受比较高强度的抗压能力，良好的文档撰写能力',
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name": '测试工程师',
                                    "salary": '1.5万-2.5万·13薪',
                                    "tag": '3年以上工作经验/本科以上学历',
                                    "description":'1.负责大型系统的测试工作，参与需求分析、设计评审、测试用例设计；',
                                    "descriptions": {
                                        "tips": ['功能测试', '性能测试', '自动化测试', '3年', '本科'],
                                        "duty":
                                            `1.负责大型系统的测试工作，参与需求分析、设计评审、测试用例设计；<br/>` +
                                            `2.独立负责大型的测试项目管理，进行任务分考核指标配，指导和带领测试工程师完成工作；<br/>` +
                                            `3.执行测试用例，记录测试结果，分折测试发现的问题，跟踪并验证Bug并确认问题，编写测试报告；<br/>` +
                                            `4.进行自动化测试及性能测试，开发辅助测试工具；<br/>` +
                                            `5.负责软件测试流程、规范的制定与实施；<br/>` +
                                            '6.完成领导交代的其他工作；' ,
                                        "require":
                                            `1.具有扎实的面向对象程序设计基础，熟悉下述技术的一种或几种：<br/>` +
                                            ` 1)Java,C++，C#,Python,Shell<br/>` +
                                            ` 2)Mysql、Oracle及相关数据库技术<br/>` +
                                            `2.具有大型网络应用系统的测试经验；<br/>` +
                                            `3.对软件测试有浓厚的兴趣和丰富的经验，有很强的分析能力和定位问题的能力；<br/>` +
                                            `4.在软件测试流程、规范的制定和实施方面有着丰富的经验；<br/>` +
                                            '5.有很强的质量意识，能严格地遵照测试流程规范；',
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                            ]
                        },
                        {
                            "type": '运营类',
                            "total":1,
                            "subList":["用户运营经理",],
                            "children": [
                                {
                                    "name":'用户运营经理',
                                    "description":'',
                                    "salary": '8千-1.5万·13薪',
                                    "tag": '3年以上互联网工作经验/2年以上用户运营经验',
                                    "descriptions": {
                                        "tips": ['用户模型', '用户模型', '3年', '本科'],
                                        "duty":
                                            `1.构建用户关系管理模型，制定用户运营策略<br/>` +
                                            `2.创新用户活动，精细化运营会员体系，增强用户粘性，用户转化率，促进平台用户增长；<br/>` +
                                            `3.用户运营策略的实施和结果验收，即时调整策略，促进目标用户的活跃和留存，进一步推动业务发展；<br/>` +
                                            `4.完成领导交代的其他工作;<br/>` +
                                            `考核指标<br/>` +
                                            `用户黏性：关注用户持续访问情况；<br/>` +
                                            `用户活跃：关注用户访问参与度；<br/>` +
                                            `用户变现：用来衡量用户创造的价值。<br/>` +
                                            `使用次数：用户使用产品的次数多了才能有更多发展的可能性。<br/>` +
                                            '访问次数：设置一个时间段，观察用户在这个时间段内的访问次数，是用户黏性的核心指标。' ,
                                        "require":
                                            `1.统招本科及以上学历；3年以上互联网工作经验，2年以上用户运营经验；<br/>` +
                                            `2.有福佑，满帮经验优先；<br/>` +
                                            `3.对数据敏感，能发现事务的本质，主动创新；<br/>` +
                                            '4.具备很强的沟通能力，工作主动新和团队合作精神；\n' ,
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                }
                            ]
                        },
                        {
                            "type": '销售类',
                            "total":2,
                            "subList":["销售","销售支持"],
                            "children": [
                                {
                                    "name":'销售',
                                    "salary": '8千-1.5万·13薪',
                                    "tag": '2年以上网络货运或油品销售经验/本科以上学历',
                                    "description":'',
                                    "descriptions": {
                                        "tips": ['网货', '油品', '2年', '本科'],
                                        "duty":
                                            `1.负责网络货运平台、油品业务平台的营销工作，完成每月业绩指标。<br/>` +
                                            `2.负责指导客户使用公司平台，规范业务操作流程，为客户提供全方位服务。<br/>` +
                                            `3.负责定期现场指导驾驶员使用公司程序及平台，培训操作流程。<br/>` +
                                            `4.负责拓展目标客户，针对大中小物流企业等新客户的关系开发、维护、业务拜访。<br/>` +
                                            `5.负责走访当地物流市场，积极拓展新用户。<br/>` +
                                            '6.负责及时跟踪处理客户反馈，维护客户关系，收集客户有关信息和需求;' ,
                                        "require":
                                            `1.本科及以上学历，2年以上网络货运或油品销售经验。<br/>` +
                                            `2.熟悉网络货运平台、油品业务平台及经营运作模式。<br/>` +
                                            `3.较强沟通表达能力、抗压能力。<br/>` +
                                            `4.熟悉物流第三方车队管理模式。<br/>` +
                                            '5.具备良好的运营管理能力及销售统筹经验。\n',
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name":'销售支持',
                                    "salary": '8千-1.5万·13薪',
                                    "tag": '有销售支持相关经验/大专以上学历',
                                    "description":'',
                                    "descriptions": {
                                        "tips": ['资料整理', '统计', '专科'],
                                        "duty":
                                            `1.销售资料的整理，搜集，归档；<br/>` +
                                            `2.负责协助销售负责人开展销售管理相关工作；<br/>` +
                                            `3.负责监控销售过程管理，统计销售日报；<br/>` +
                                            `4.负责根据业务升级不定期迭代相关销售工具包；<br/>` +
                                            `5.部门间沟通协作与跨部门沟通；<br/>` +
                                            '6.参与并协助制定销售团队支持方案，包含策略，激励制度。' ,
                                        "require":
                                            `1.大专以上学历，有销售支持相关经验；<br/>` +
                                            `2.对数据敏感，擅长数据分析，熟练使用办公软件；<br/>` +
                                            '3.善于沟通，做事认真负责，有良好的团队意识和承压能力，学习能力强，拥抱变化',
                                        "address": '北京顺义区顺西南路2号院',
                                        "contact":
                                            `联系人：张藏文  人力资源部<br/>` +
                                            `电话：18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                }
                            ]
                        },
                        {
                            "type": '管理类',
                            "total":0,
                            "children": ['']
                        },
                    ],
                    "jobDetail":{
                        "jobDescription":"职位描述",
                        "bodyTitle1":"岗位职责",
                        "bodyTitle2":"任职要求",
                        "bodyTitle3":"工作地点",
                        "bodyTitle4":"联系方式"
                    }
                }
            },
            "contactUs": {
                "title": "联系我们",
                "left": {
                    "type": ["电话", "地址"],
                    "addressInfo":[
                        {"province": "北京", "phone": "400-080-5656", "address": "北京市顺义区顺西南路2号院"},
                        {"province": "安徽", "phone": "400-080-5656", "address": "安徽省安庆市迎江区紫峰大厦B座1006"},
                        {"province": "山东", "city":"菏泽", "phone": "400-080-5656", "address": "山东省菏泽市郓城县"},
                        {"province": "山东", "city":"青岛", "phone": "400-080-5656", "address": "山东省青岛市前湾保税港区"}
                    ]
                },
                "right": {
                    "mapInfo": [
                        {"province": "北京", "image": beijing},
                        {"province": "安徽", "image": anhui},
                        {"province": "山东", "city":"菏泽", "image": shandong}
                    ]
                }
            }
        },
        "download": {
            "title": "APP下载",
            "button": {
                "ios": {"text": "下载应用，请到", "storeText": "APP Store"},
                "android": {"text": "", "storeText": "Android"}
            },
            "content": {
                "mlTipsText":"左右滑动查看",
                "left": {
                    "title": "智猪货主",
                    "des": "拥有数万严格审核的司机及车辆资源，提供优质的货源信息，为全国各地货主提供优质车源。",
                    "subTitle": "海量车源，司机位置实时可见。"
                },
                "right": {
                    "title": "智猪司机",
                    "des": "多渠道优惠加油、尿素加注、维修保养，固定线路招标、司机招聘，卡友圈、小视频社区服务。",
                    "subTitle": "货源真实、在线实时接单、在途异常报备、结算实时到账。"
                }
            }
        },
    }
}
export const english = {
    "app": {
        "common": {
            "foot": {
                "footLeft": {
                    "one": "Logistics digital intelligence one-stop solution service provider",
                    "two": "7*24 hours customer service",
                    "three": "400-080-5656",
                    "four": "Address: Courtyard 2, Shunxi South Road, Shunyi District, Bejjing"
                },
                "footRightTextList": [
                    {"name":"Product Center","key":"product"},
                    {"name":"about Us","key":"about"},
                    {"name":"contact us","key":"about","tabName":"contactUs"}
                ],
                "copyRight_1": "北京星日朗供应链管理有限公司 Copyright © www.zhipig.com All rights reserved. ",
                "copyRight_2": "京ICP备2021020961号-1"
            },
            "news": {
                "title": "Latest News",
                "newsItemBtn": "Click to View",
                "moreNews": "more news"
            },
            "footerCodeList":[
                {"name":"Mini Program","img":xiaochengxu},
                {"name":"Driver","img":siji},
                {"name":"main terminal","img":huozhu}
            ],
            "jobAndNewDetailBtn":"return"
        },
        "nav": {
            "language":"中文",
            "navList": [
                {"name":"Home","key":"home"},
                {"name":"Product","key":"product"},
                {"name":"About","key":"about"},
                {"name":"Download App","key":"download"}
            ]
        },
        "home": {
            "banner": {
                "centerText": "Logistics intelligent one-stop solution service provider",
                "bottomText": "Swipe to see more"
            },
            "bodyContent1": {
                "title": "Zhipig Big Data",
                "rowOne":[
                    {
                        "name":'Business Coverage Cities',
                        "num":0,
                        "unit":'+',
                        "width":'12rem'
                    },
                    {
                        "name":'Business Coverage Route',
                        "num":0,
                        "unit":'W+',
                        "width":'4rem'
                    },
                    {
                        "name":'Accumulated transportation mileage',
                        "num":0,
                        "unit":'W',
                        "width":'22rem'
                    }
                ],
                "rowTwo":[
                    {
                        "name":'Cumulative number of completed consignment orders',
                        "num":0,
                        "unit":'W+',
                        "width":'12rem'
                    },
                    {
                        "name":'Cumulative number of users',
                        "num":0,
                        "unit":'W+',
                        "width":'8rem'
                    }
                ],
            },
            "bodyContent2": {
                "title": "Intelligent Logistics Ecosystem",
                "content": [
                    "Relying on the intelligent algorithm of the Internet, build a resource pool of hundreds of thousands of transportation capacity, accurately solve the needs of cargo owners for vehicles, and effectively manage and control the process on the platform, so as to achieve clear settlement, fund security, tax compliance, and easy operation, providing users with high-quality Serve.",
                    "Provide refined management for enterprises, make the operation simple and convenient, make the whole process of waybill traceable, monitor the overall transportation process,realize risk control, provide visual reports, upstream and downstream settlement account management,fleet management and other services;integrate vehicles,GPS,Accessories and other assets are online,so that the intelligent management of assets can empower enterprises.",
                    "Provide users with 10,000+ cooperative gas stations to achieve nationwide coverage of gas stations, making it more convenient and efficient for drivers to refuel. Provide cooperative customers with services such as online management of oil products, clearing of oil accounts, and diesel refilling, solve the problem of difficult management of fuel card loads and physical cards for companies, and reduce fuel costs for logistics companies or individual drivers, reducing costs and increasing efficiency.",
                    ["Maintenance","supply chain finance", "insurance service", "Urea filling", "Capacity Recruitment", "driver recruitment", "flexible working", "ETC service"]
                ]
            },
            "bodyContent3": {
                "title": "Partner"
            }
        },
        "product": {
            "bodyContent1": {
                "title": "Nebula Network Freight Service",
                "des": "Provide online vehicle and cargo matching service, through the algorithm matching of cargo source and capacity + all-round user portrait, to accurately solve the needs of using vehicles and finding goods; the platform achieves capital security, tax regulations, easy operation, conforms to national policies, and plans the freight market Information flow, trajectory flow, ticket logistics, contract flow and capital flow. It solves the customer billing problem and the driver's compliance risk and personal tax problem, and comprehensively improves the driver's bicycle settlement and tax management.",
                "bodyTitle":"Algorithm link intelligent planning" ,
                "bodyFoot": [
                    {
                        "title": "route planning method",
                        "content": "Break through business barriers, use the platform to link sources of goods, and use intelligent algorithms such as two-point round trip and three-point cycle to eliminate abnormal factors to form the optimal route, reduce costs for enterprises, and improve efficiency for drivers."
                    },
                    {
                        "title": "User portrait system",
                        "content": "The establishment of the platform user system, the utilization of platform capacity resources, and the continuous enrichment and update of user portrait data through the personal system provide strong support for user recall, event operation, and vehicle and cargo matching."
                    },
                    {
                        "title": "Intelligent Recommendation Algorithm",
                        "content": "Algorithms are used to match suitable drivers for sources of goods, and by recommending sources of goods for drivers to increase the transaction rate of sources of goods, compared with one-way communication with offline contacts, the efficiency of matching drivers with sources of goods is greatly improved."
                    }
                ]
            },
            "bodyContent2": {
                "title": "Zhizhu intelligent management system",
                "bodyLeft": {
                    "c1": "Intelligent system SAAS level service",
                    "c2": "Meet the needs of multi-scenario business operations, clear enterprise data, release human resources, and reduce service costs",
                    "c3": "By building an open SAAS-based transportation management platform, we can open up the upstream and downstream documents, logistics and settlement information of the supply chain to help customers on the platform manage the overall transportation business more efficiently and conveniently.",
                    "leftBottom": [
                        {
                            "title": "Platform advantage",
                            "content": "Combining informatization with smart devices, it supports GPS location management, vehicle temperature control management, early warning of dangerous road sections, feedback on dangerous driving, etc."
                        },
                        {
                            "title": "data advantage",
                            "content": "Data informatization makes the production work of the enterprise more efficient and transparent, combined with the data analysis of each module, the revenue and expenditure of the enterprise are more comprehensive and clearer."
                        }
                    ]
                },
                "bodyRight": {
                    "title": "Vehicle Management Services",
                    "content": "Meet the management needs of the company's own vehicles, grasp real-time data such as vehicle location, trajectory, fuel consumption, etc., and monitor the driver's driving behavior in real time through the driver rating system to reduce potential safety hazards.",
                    "rightBottom":{
                        "tagOne": ["personnel management", "driving habits", "behavior monitoring", "Real-time reporting", "driver portrait", "driver recruitment"],
                        "tagTwo": ["location track", "fuel consumption management", "task association", "Violation and annual review", "Accidents and Violations", "Insurance and Leasing"]
                    }
                }
            },
            "bodyContent3": {
                "title": "Digital Energy Services",
                "des": "Provide users with 10,000+ cooperative gas stations to achieve nationwide coverage of gas stations, making it more convenient and efficient for drivers to refuel. Provide cooperative customers with services such as online management of oil products, clearing of oil accounts, and diesel refilling, solve the problem of difficult management of fuel card loads and physical cards for companies, and reduce fuel costs for logistics companies or individual drivers, reducing costs and increasing efficiency .",
                "img": productC3EnImg
            },
            "bodyContent4": {
                "title": "Value-added services",
                "des": "Carry out a full-scenario ecological layout around shippers and drivers, break through resource barriers through technological innovation and the establishment of information systems, provide high-quality shipping services for shippers in business, provide high-quality source information for drivers, and combine algorithms and System informatization reduces costs and increases efficiency for enterprises, synchronously integrates supply chain finance, urea filling, insurance, maintenance, ETC, driver recruitment, capacity recruitment, flexible employment and other services, and serves cargo owners and drivers with heart.",
                "body": {
                    "left": [
                        {
                            "name": "Maintenance",
                            "content": "Zhizhu Technology cooperates with 8000+ maintenance sites across the country to record vehicle status online and provide maintenance warnings; special personnel conduct offline inspections of site service capabilities, professional maintenance technology, business certification and other credits to ensure site service quality and reduce driver use risks and usage costs."
                        },
                        {
                            "name": "supply chain finance",
                            "content": "Connect with multiple third-party financial channels, choose the most suitable partners for logistics customers, through the comprehensive supervision of cloud data links, and with the help of the credit system jointly built by banks and platforms, increase the intervention of the financial system to achieve transportation, Online integrated supply chain services such as finance can solve the problem of corporate capital flow and create greater profit margins for corporate income."
                        },
                        {
                            "name": "insurance service",
                            "content": "Based on big data analysis of drivers' behaviors such as driving, refueling, maintenance, etc., it helps companies to make intelligent assessments, helps drivers to make intelligent early warnings, reduces the accident rate during transportation, and reduces the insurance compensation rate."
                        },
                        {
                            "name": "Urea filling",
                            "content": "Zhizhu Technology cooperates with a number of urea brands to set up refilling stations at major express delivery stations across the country to provide drivers and car owners with online payment and ready-to-go urea refilling services."
                        },
                        {
                            "name": "Capacity Recruitment",
                            "content": "Cargo owners can publish recruitment information through the Zhizhu platform, and drivers or car owners can participate in bidding through the Zhizhu platform. Through big data, the real operating conditions of each fleet can be presented, thereby recommending high-quality fleets and drivers for cargo owners."
                        },
                        {
                            "name": "driver recruitment",
                            "content": "Users can post recruitment information on the Zhizhu platform to provide car owners with high-quality drivers and ensure the efficiency of vehicle operation. Drivers can find a satisfactory job through the platform and increase their personal income."
                        },
                        {
                            "name": "flexible working",
                            "content": "Zhizhu Technology provides flexible employment services for enterprises through the platform, helping enterprises and temporary employees change from traditional labor employment relations to platform-based value cooperation and exchange relations, so that human resources can truly optimize the allocation."
                        },
                        {
                            "name": "ETC service",
                            "content": "Docking with the ETC ticketing platform of Biaogen.com, obtaining ETC invoices in time after the transportation is completed, automatically classifying and integrating ETC invoices, saving the time and cost of offline reconciliation of logistics companies."
                        }


                    ],
                    "right": {
                        "title": "value-added products",
                        "content": [
                            {"class": "icon2","nameFlag": "Maintenance", "name": "Maintenance", "index": 0},
                            {"class": "icon3","nameFlag":"flexible working", "name": "flexible working", "index": 6},
                            {"class": "icon4","nameFlag": "Capacity Recruitment", "name": "Capacity Recruitment", "index": 4},
                            {"class": "icon5","nameFlag":"Urea filling", "name": "Urea filling", "index": 3},
                            {"class": "icon6","nameFlag": "insurance service", "name": "insurance service", "index": 2},
                            {"class": "icon7","nameFlag":"ETC service", "name": "ETC service", "index": 7},
                            {"class": "icon8","nameFlag": "driver recruitment", "name": "driver recruitment", "index": 5},
                            {"class": "icon9","nameFlag":"supply chain finance", "name": "supply chain finance", "index": 1}
                        ]
                    }
                }
            }
        },
        "about": {
            "title": "Provide intelligent one-stop solutions for the logistics industry",
            "navList": [
                {"name":"company profile","code":"companyProfile"},
                {"name":"development path","code":"developmentHistory"},
                {"name":"social responsibility","code":"socialResponsibility"},
                {"name":"Latest News","code":"newTrends"},
                {"name":"Recruitment","code":"talentAcquisition"},
                {"name":"contact us","code":"contactUs"}
            ],
            "companyProfile": {
                "title": "About Zhizhu Technology",
                "content": "Founded in 2018 with a registered capital of 50 million, it is an Internet innovation platform company that provides intelligent services for small and medium-sized logistics companies. In the context of the transformation and upgrading of traditional industries and the continuous penetration of the Internet, the efficient integration and full utilization of resources has created a \"vehicle-cargo matching\" model, which has opened up a new path for the development of the traditional freight industry."
            },
            "developmentHistory": {
                "title": "development path",
                "timeLine": [
                    {"date": "2018", "des": "Zhipig was established"},
                    {"date": "2019", "des": "Information stage"},
                    {"date": "2021", "des": "Coordinated development of diversified products\n"},
                    {"date": "2022", "des": "Intelligent and digital stage"},
                    {"date": "", "des": "Our story continues..."}
                ]
            },
            "socialResponsibility": {
                "title": "social responsibility",
                "des": ["Promote ecological environment protection and reduce carbon emissions. Through big data intelligent algorithms, advanced GPS navigation and other technologies to provide drivers with route planning, improve the effective operating mileage, reduce vehicle transportation energy consumption year-on-year, and reduce carbon emissions.", "Committed to road transport safety. Zhipig Technology adopts active safety equipment, intelligent face recognition system and other technologies to help drivers avoid safety accidents caused by fatigue driving and reduce the fatality rate of traffic accidents. At the same time, it ensures the timeliness of transportation and the safety of goods.", "Promote the healthy development of the transportation industry and provide a source of income for individual drivers. The platform reduces costs and increases efficiency for traditional logistics companies, and improves corporate profitability. The platform provides waybill matching services for 200,000 drivers every year, reduces channel fees such as fleet intermediaries, increases drivers' income, and promotes social stability and harmonious development."]
            },
            "talentAcquisition": {
                "title": "Ready to join us?",
                "button": "view jobs",
                "body":[
                    {"type": "vision", "des": "Become the first choice of digital intelligence one-stop service platform for China's logistics"},
                    {"type": "mission", "des": "Technology empowerment reduces costs and increases efficiency for enterprises"},
                    {"type": "values", "des": "Professional innovation effectively empowers honesty and pragmatism for win-win cooperation"}
                ],
                "jobs":{
                    "leftTitle":"Recruitment content",
                    "showJobsTypeBtn":"view categories",
                    "pickerTitle":"job categories",
                    "pickerBtnText":["cancel","confirm"],
                    "jobsList": [
                        {
                            "type": 'Product Technology\n',
                            "total":6,
                            "subList":["Product Director","JAVA development engineer","WEB development engineer","IOS development engineer","Android development engineer","Test Engineer"],
                            "children": [
                                {
                                    "name":'Product Director',
                                    "salary": '20,000-40,000 13 salary',
                                    "tag": 'Computer-related major / bachelor degree or above',
                                    "description":'1. Familiar with logistics, car-free carriers, and supply chain market-related policies and regulations, with market sensitivity and forward-looking awareness.',
                                    "descriptions": {
                                        "tips": ['logistics', 'Online goods', 'supply chain', 'data', '5 years', 'undergraduate'],
                                        "duty":
                                            `1. Familiar with logistics, car-free carriers, and supply chain market-related policies and regulations, with market sensitivity and forward-looking awareness.<br/>` +
                                            `2. Possess a business perspective, be able to think deeply about product design from the perspective of market and revenue, and have a certain innovative ability.<br/>` +
                                            `3. Strong control experience in interaction design, user experience, interface, etc.<br/>`+
                                            `4. Have an in-depth understanding and unique thinking on the business model, special requirements, and future trends of the logistics industry, have good business sensitivity and vision, and be able to keenly capture product opportunities and data value.<br/>` +
                                            `5. Good document organization skills, good written and oral expression skills, good interpersonal communication skills, and good logical thinking skills.<br/>` +
                                            '6. Independently led at least one platform-level and enterprise-level project or product.',
                                        "require":
                                            `1. Computer-related majors, bachelor degree or above.<br/>` +
                                            `2. Received training in strategic management, management capability development, project management, innovation management, leadership, etc.<br/>` +
                                            '3. More than 5 years of product manager or product operation experience, experience in logistics-related Internet platforms such as Fuyou, Yunmanman, G7, Luge and Zouyou.com is preferred.',
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name":'JAVA development engineer',
                                    "salary": '15,000-25,000 13 salary',
                                    "tag": 'More than 2 years working experience/college degree or above',
                                    "description":'1. Responsible for the needs analysis, development, and daily maintenance of the project to ensure the normal progress of the company\'s projects.',
                                    "descriptions": {
                                        "tips": ['JAVA', 'J2EE', 'Spring', 'spring-boot', 'backend development', '2 years',"specialist"],
                                        "duty":
                                            `1. Responsible for the needs analysis, development, and daily maintenance of the project to ensure the normal progress of the company's projects.<br/>` +
                                            `2. Participate in software product project planning and formulate specific project implementation plans.<br/>` +
                                            `3. Integrate and optimize various resources required for project development.<br/>` +
                                            `4. Responsible for the improvement of software development technology and norms and standard processes.<br/>` +
                                            `5. Participate in the design and analysis of software systems.<br/>` +
                                            '6. Complete the design, development and programming tasks of the corresponding module software according to the development progress and task assignment.',
                                        "require":
                                            `1. Computer technology or electronic information, electronic engineering and other related majors are preferred, with a college degree or above.<br/>` +
                                            `2. More than 2 years of work experience, experience in logistics-related Internet platforms such as Yunmanman, G7, Luge, Fuyou and Zouyou.com is preferred.。<br/>` +
                                            `3. More than 2 years of work experience, experience in logistics-related Internet platforms such as Yunmanman, G7, Luge, Fuyou and Zouyou.com is preferred.<br/>` +
                                            `4. Strong dedication, studious and team spirit.<br/>` +
                                            `5. Solid foundation of JAVA language. Familiar with the overall structure of J2EE, proficient in using open source frameworks such as Spring and spring-boot, and familiar with JavaScript scripting language. Understand the HTTP protocol, json, nginx, etc.<br/>` +
                                            '6. Master simple linux commands.',
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name": 'WEB development engineer',
                                    "salary": '15,000-25,000 13 salary',
                                    "tag": 'More than 3 years working experience/Bachelor degree or above',
                                    "description":'1. Responsible for the realization of the front end of the product, including PC, mobile Web, WeChat applet, etc.',
                                    "descriptions": {
                                        "tips": ['Web front end', 'H5 applet', 'JavaScript', '3 years', 'undergraduate'],
                                        "duty":
                                            `1. Responsible for the realization of the front end of the product, including PC, mobile Web, WeChat applet, etc.<br/>` +
                                            `2. Cooperate with product managers, deeply participate in product demand discussions, function definitions, etc.<br/>` +
                                            '3. Design a good code structure and continuously iteratively refactor.',
                                        "require":
                                            `1. Bachelor degree or above, computer related major.<br/>` +
                                            `2. At least 3+ years of experience in web front-end or H5 applet development, 4+ years of experience is preferred.<br/>` +
                                            `3. Master JavaScript language, ES6 specification, communication protocol, network and other front-end knowledge.<br/>` +
                                            `4. Love front-end technology, have strong curiosity and thirst for knowledge, have good coding standards, and have a deep understanding of various Web front-end technologies.<br/>` +
                                            `5. Pay attention to Web development, be passionate about new technologies, expect or have developed excellent products.<br/>` +
                                            `6. Familiar with the server-side Web application structure.<br/>` +
                                            `7. Serious work, rigorous attitude and strong sense of responsibility. It has high requirements for the improvement of its own technology and the quality and performance of software.<br/>` +
                                            `8. Have good analytical and problem-solving skills.<br/>` +
                                            `9. Have good communication skills and excellent teamwork skills.<br/>` +
                                            '10. Those with well-known open source projects are preferred. Experience in large-scale project architecture is preferred.',
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name": 'IOS development engineer',
                                    "salary": '15,000-25,000 13 salary',
                                    "tag": 'More than 3 years working experience/Bachelor degree or above',
                                    "description":'1. Participate in product design: According to business needs, actively participate in product discussion, definition, design, etc.',
                                    "descriptions": {
                                        "tips": ['IOS', 'Objective-C', 'SDK', 'application development', '3 years',"undergraduate"],
                                        "duty":
                                            `1. Participate in product design: Actively participate in product discussion, definition, design, etc. according to business needs;<br/>` +
                                            `2. Compilation of technical documents: According to the requirements of the company's software development management specifications, according to the tasks assigned by the superior, prepare detailed design documents for software development to ensure Transparency and controllability of the development process;<br/>` +
                                            `3. Software coding: According to the development documents, carry out the software coding of important subsystems/modules, and participate in the technical research on specific technical difficulties according to the specific difficulties in the system. Complete the code development plan as planned to ensure the high-quality completion of the project;<br/>` +
                                            `4. Product testing: According to the company's product testing related processes and product characteristics, cooperate with the testing department and business department to carry out product testing and acceptance work, organize the installation and debugging of the software system, and ensure The product is released smoothly;<br/>` +
                                            '5. Quality activities: Participate in quality assurance activities such as code reading of software modules, formal code inspections, formal inspections of design documents, and design reviews to reduce software product defects.' ,
                                        "require":
                                            `1. Bachelor degree or above, more than 3 years of iOS development experience<br/>` +
                                            `2. Proficient in Objective-C language, familiar with the principle of operating system memory management and code compilation, with good code specifications<br/>` +
                                            `3. Proficient in iOS development framework, familiar with common three parties Use of open source libraries<br/>` +
                                            `4. Proficient in using common design patterns, able to adopt reasonable design patterns according to different scenarios<br/>` +
                                            `5. Has launched iOS products, and has experience in product upgrade iterations<br/>` +
                                            '6. Has good communication and coordination skills, and can accept relatively high-intensity resistance Ability to press; good documentation skills',
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name": 'Android development engineer',
                                    "salary": '15,000-25,000 13 salary',
                                    "tag": 'More than 3 years working experience/college degree or above',
                                    "description":'1. Participate in product design: According to business needs, actively participate in product discussion, definition, design, etc.;',
                                    "descriptions": {
                                        "tips": ['Android', 'SDK', 'application development', '3 years',"undergraduate"],
                                        "duty":
                                            `1. Participate in product design: Actively participate in product discussion, definition, design, etc. according to business needs; <br/>` +
                                            `2. Compilation of technical documents: According to the requirements of the company's software development management specifications, according to the tasks assigned by the superior, prepare detailed design documents for software development to ensure Transparency and controllability of the development process;<br/>` +
                                            `3. Software coding: According to the development documents, carry out the software coding of important subsystems/modules, and participate in the technical research on specific technical difficulties according to the specific difficulties in the system. Complete the code development plan as planned to ensure the high-quality completion of the project;<br/>`+
                                            `4. Product testing: According to the company's product testing related processes and product characteristics, cooperate with the testing department and business department to carry out product testing and acceptance work, organize the installation and debugging of the software system, and ensure The product is released smoothly;<br/>` +
                                            '5. Quality activities: Participate in quality assurance activities such as code reading of software modules, formal code inspections, formal inspections of design documents, and design reviews to reduce software product defects.' ,
                                        "require":
                                            `1. College degree or above, more than 3 years of Android development experience, independent development experience is preferred<br/>` +
                                            `2. Familiar with Android system architecture, framework, underlying library, and UI design<br/>` +
                                            `3. Familiar with Android development tools, able to use Android SDK proficiently<br/>` +
                                            `4. Familiar with the network communication mechanism under Android, have a certain understanding and experience of Socket communication, TCPIP, Http<br/>` +
                                            '5. Have good communication and coordination skills, can accept relatively high-intensity stress resistance, and good document writing skills',
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name": 'Test Engineer',
                                    "salary": '15,000-25,000 13 salary',
                                    "tag": 'More than 3 years working experience/Bachelor degree or above',
                                    "description":'1. Responsible for the testing of large-scale systems, participating in requirements analysis, design review, and test case design;',
                                    "descriptions": {
                                        "tips": ['function test', 'Performance Testing', 'automated test', '3 years', 'undergraduate'],
                                        "duty":
                                            `1. Responsible for the testing of large-scale systems, participating in demand analysis, design review, and test case design;<br/>` +
                                            `2. Independently responsible for the management of large-scale test projects, assigning task assessment indicators, guiding and leading test engineers to complete the work;<br/>` +
                                            `3. Executing test cases , Record the test results, break down the problems found in the test, track and verify the bug and confirm the problem, and write the test report;<br/>` +
                                            `4. Conduct automated testing and performance testing, and develop auxiliary testing tools;<br/>` +
                                            `5. Responsible for the formulation and implementation of software testing processes and specifications ;<br/>` +
                                            '6. Complete other work assigned by the leader;' ,
                                        "require":
                                            `1. Have a solid object-oriented programming foundation, and be familiar with one or more of the following technologies:<br/>` +
                                            ` 1) Java, C++, C#, Python, Shell<br/>` +
                                            ` 2) Mysql, Oracle and related database technologies<br/>` +
                                            `2. Have experience in large-scale network application systems Testing experience;<br/>` +
                                            `3. Have a strong interest in and rich experience in software testing, and have a strong ability to analyze and locate problems;<br/>` +
                                            `4. Have rich experience in software testing processes, formulation and implementation of specifications;<br/>` +
                                            '5. Have Strong quality awareness, can strictly follow the test process specification;',
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                            ]
                        },
                        {
                            "type": 'Operation',
                            "total":1,
                            "subList":["User Operations Manager"],
                            "children": [
                                {
                                    "name":'User Operations Manager',
                                    "description":'',
                                    "salary": '8,000-15,000 13 salary',
                                    "tag": 'More than 3 years of Internet work experience / more than 2 years of user operation experience',
                                    "descriptions": {
                                        "tips": ['user model', '3 years', 'undergraduate'],
                                        "duty":
                                            `1. Build a user relationship management model, formulate user operation strategies<br/>` +
                                            `2. Innovate user activities, refine the operation of the membership system, enhance user stickiness, user conversion rate, and promote platform user growth;<br/>` +
                                            `3. Implementation of user operation strategies and result acceptance, real-time adjustments strategy to promote the activity and retention of target users, and further promote business development;<br/>` +
                                            `4. Complete other work assigned by the leader;<br/>` +
                                            `assessment index<br/>` +
                                            `user stickiness: pay attention to the continuous visit of users;<br/>` +
                                            `active users: pay attention to the participation of users in visits;<br/>` +
                                            `user realization: Measure the value created by users.<br/>` +
                                            `Number of times of use: The more times users use the product, the more possibilities there will be for development.<br/>` +
                                            'Number of visits: Set a time period and observe the number of visits by users within this time period, which is the core indicator of user stickiness.' ,
                                        "require":
                                            `1. Unified recruitment of undergraduate degree or above; more than 3 years of Internet work experience, more than 2 years of user operation experience; <br/>` +
                                            `2. Blessed, full help experience is preferred;<br/>` +
                                            `3. Sensitive to data, able to discover the essence of affairs, and take the initiative to innovate;<br/>` +
                                            '4. Possess strong communication skills, work initiative and teamwork spirit;\n' ,
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                }
                            ]
                        },
                        {
                            "type": 'Sales',
                            "total":2,
                            "subList":["Sale","sales support"],
                            "children": [
                                {
                                    "name":'Sale',
                                    "salary": '8,000-15,000 13 salary',
                                    "tag": 'More than 2 years of network freight or oil sales experience / bachelor degree or above',
                                    "description":'',
                                    "descriptions": {
                                        "tips": ['Online goods', 'oil', '2 years', 'undergraduate'],
                                        "duty":
                                            `1. Responsible for the marketing work of the online freight platform and oil product business platform, and complete the monthly performance indicators.<br/>` +
                                            `2. Responsible for guiding customers to use the company platform, standardizing business operation procedures, and providing customers with all-round services.<br/>` +
                                            `3. Responsible for regular on-site guidance of drivers to use company procedures and platforms, and training of operating procedures.<br/>` +
                                            `4. Responsible for expanding target customers, developing, maintaining and visiting new customers such as large, medium and small logistics companies.<br/>` +
                                            `5. Responsible for visiting the local logistics market and actively expanding new users.<br/>` +
                                            '6. Responsible for tracking and processing customer feedback in a timely manner, maintaining customer relationship, and collecting relevant information and needs of customers;' ,
                                        "require":
                                            `1. Bachelor degree or above, more than 2 years experience in network freight or oil sales.<br/>` +
                                            `2. Familiar with the network freight platform, oil business platform and operation mode.<br/>` +
                                            `3. Strong communication skills and ability to withstand pressure.<br/>` +
                                            `4. Familiar with logistics third-party fleet management mode.<br/>` +
                                            '5. Possess good operation management ability and sales coordination experience.\n',
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                },
                                {
                                    "name":'sales support',
                                    "salary": '8,000-15,000 13 salary',
                                    "tag": 'Sales support related experience / college degree or above',
                                    "description":'',
                                    "descriptions": {
                                        "tips": ['Data collation', 'statistics', 'specialist'],
                                        "duty":
                                            `1. Organize, collect, and file sales materials;<br/>` +
                                            `2. Responsible for assisting the sales person in charge to carry out sales management related work;<br/>` +
                                            `3. Responsible for monitoring the sales process management and statistical sales daily;<br/>` +
                                            `4. Responsible for iterating relevant sales toolkits from time to time according to business upgrades;<br/>` +
                                            `5. Inter-departmental communication, collaboration and cross-departmental communication;<br/>` +
                                            '6. Participate in and assist in formulating sales team support plans, including strategies and incentive systems.' ,
                                        "require":
                                            `1. College degree or above, experience in sales support;<br/>` +
                                            `2. Sensitive to data, good at data analysis, proficient in using office software;<br/>` +
                                            '3. Good at communication, serious and responsible in work, good team awareness and pressure-bearing ability, strong learning ability, embrace change',
                                        "address": 'Courtyard 2, Shunxi South Road, Shunyi District, Beijing',
                                        "contact":
                                            `Contact: Zhang Zangwen Human Resources Department<br/>` +
                                            `Tel:18610871303<br/>` +
                                            'E-mail：zhangcangwen@bohuawuliu.com'
                                    }
                                }
                            ]
                        },
                        {
                            "type": 'Management',
                            "total":0,
                            "children": ['']
                        },
                    ],
                    "jobDetail":{
                        "jobDescription":"job description",
                        "bodyTitle1":"Job Responsibilities",
                        "bodyTitle2":"job requirements",
                        "bodyTitle3":"work place",
                        "bodyTitle4":"Contact information"
                    }
                }
            },
            "contactUs": {
                "title": "contact us",
                "left": {
                    "type": ["Tel", "Address"],
                    "addressInfo":[
                        {"province": "Beijing", "phone": "400-080-5656", "address": "Courtyard 2, Shunxi South Road, Shunyi District, Beijing"},
                        {"province": "Anhui", "phone": "400-080-5656", "address": "Room 1006, Block B, Zifeng Building, Yingjiang District, Anqing City, Anhui Province"},
                        {"province": "Shandong", "city":"Heze", "phone": "400-080-5656", "address": "Yuncheng County, Heze City, Shandong Province"},
                        {"province": "Shandong", "city":"Qingdao", "phone": "400-080-5656", "address": "Qianwan Bonded Port Area, Qingdao City, Shandong Province"}
                    ]
                },
                "right": {
                    "mapInfo": [
                        {"province": "Beijing", "image": beijing},
                        {"province": "Anhui", "image": anhui},
                        {"province": "Shandong", "city":"Heze", "image": shandong}
                    ]
                }
            }
        },
        "download": {
            "title": "APP download",
            "button": {
                "ios": {"text": "", "storeText": "APP Store"},
                "android": {"text": "", "storeText": "Android"}
            },
            "content": {
                "mlTipsText":"swipe left and right to view",
                "left": {
                    "title": "Zhipig owner",
                    "des": "With tens of thousands of drivers and vehicle resources under strict review, it provides high-quality supply information and provides high-quality vehicle sources for cargo owners across the country.",
                    "subTitle": "Massive car source, real-time visibility of the driver's location."
                },
                "right": {
                    "title": "Zhipig Driver",
                    "des": "Multi-channel preferential refueling, urea filling, maintenance, fixed line bidding, driver recruitment, card friend circle, small video community service.",
                    "subTitle": "The source of goods is real, orders are received online in real time, exceptions are reported in transit, and settlement is credited in real time."
                }
            }
        }
    }
}