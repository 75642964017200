<template>
  <div class="talentAcquisition">
    <div>
      <div>
        <img src="../../../assets/images/about/ren_cai_zhao_pin@2x.png" alt="">
        <p>
          {{ language.app.about.talentAcquisition.title }}
          <button @click="$router.push({name:'jobsList'})">{{ language.app.about.talentAcquisition.button }}</button>
        </p>
      </div>
      <ul>
        <li v-for="item in language.app.about.talentAcquisition.body"><span>{{item.type}}</span>{{ item.des }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {useLanguageStore} from "@/store/languageStore";
export default {
  name: 'talentAcquisition',
  data(){
    return{
      store:{},
      language:{},
    }
  },
  beforeMount() {
    this.getLanguage()
  },
  methods:{
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  }
}
</script>

<style>

</style>