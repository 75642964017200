<template>
  <div class="developmentHistory">
    <div>
      <p>{{ language.app.about.developmentHistory.title }}</p>
      <ul class="timeLine">
        <li>
          <p>{{ language.app.about.developmentHistory.timeLine[0].date }}</p>
          <span>{{ language.app.about.developmentHistory.timeLine[0].des }}</span>
        </li>
        <li>
          <p>{{ language.app.about.developmentHistory.timeLine[1].date }}</p>
          <span>{{ language.app.about.developmentHistory.timeLine[1].des }}</span>
        </li>
        <li>
          <p>{{ language.app.about.developmentHistory.timeLine[2].date }}</p>
          <span :class="{mlENTop:isMl}">{{ language.app.about.developmentHistory.timeLine[2].des }}</span>
        </li>
        <li>
          <p>{{ language.app.about.developmentHistory.timeLine[3].date }}</p>
          <span>{{ language.app.about.developmentHistory.timeLine[3].des }}</span>
        </li>
        <li>
          <p>{{ language.app.about.developmentHistory.timeLine[4].des }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {useLanguageStore} from "@/store/languageStore";

export default {
  name: 'developmentHistory',
  data(){
    return{
      isMl:false,
      store:{},
      language:{},
    }
  },
  beforeMount() {
    let lang = localStorage.getItem('language')
    this.isMl = window.innerWidth <= 750 && lang == 'EN'
    this.getLanguage()
  },
  methods:{
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  },
  watch:{
    store:{
      deep:true,
      handler(newV){
        this.isMl = window.innerWidth <= 750 && newV.languageObj.app.nav.language == '中文'
      }
    },
  },
}
</script>

<style>

</style>