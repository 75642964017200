import { createApp } from 'vue'
import router from '@/router';
import App from './App.vue'
import store from "@/store/plugin";
import VueFullPage from 'vue-fullpage.js'
import "./assets/css/common.css"
import 'animate.css';
import 'vue-fullpage.js/dist/style.css'
import VueCountTo from "../src/components/countTo/vue-countTo";
const VueScrollTo = require('vue-scrollto')
const app = createApp(App)
app.config.globalProperties.eventBus  =
app.use(VueFullPage)
app.use(router)
app.use(store)
app.use(VueScrollTo)
app.component("CountTo", VueCountTo)
app.mount('#app')
