<template>
  <div class="newTrends">
    <div>
      <p>
        {{ language.app.common.news.title }}
      </p>
      <div class="news">
        <div class="content">
          <div>
            <div  @click="newsDetail(item,index)" v-for="item in newsList">
              <div class="img-box">
                <div>
                  <div @click="newsDetail(item,index)">
                    {{ language.app.common.news.newsItemBtn }}
                  </div>
                </div>
                <img :src="item.coverImageUrl">
              </div>
              <div class="title-box">
                <p>{{ item.title }}</p>
                <p>{{ item.createTime }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="news-pages">
        <Pagination :pageTotal="pageTotal" :currentPage="currentPage" @getCurrent="getCurrent"></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/views/news/Pagination.vue'
import {getNewsPage} from "@/views/apiJs/newsApi";
import {useLanguageStore} from "@/store/languageStore";
export default {
  name: 'newTrends',
  components: {
    Pagination
  },
  data() {
    return {
      isShowMany: false,
      currentPage: 1,
      dataTotal: 0,
      pageSize: 8,
      newsList: [],
      store:{},
      language:{},
    }
  },
  computed: {
    pageTotal() {
      return this.dataTotal > this.pageSize ? Math.ceil(this.dataTotal / this.pageSize) : 1
    },
  },
  mounted() {
    this.initData()
  },
  beforeMount() {
    this.getLanguage()
  },
  methods: {
    async initData(currentPage = 1) {
      await getNewsPage({currentPage: currentPage,pageSize:this.pageSize}).then(res => {
        this.newsList = res.data.records
        this.dataTotal = res.data.total
      })
    },
    newsDetail(item) {
      this.$router.push({name: 'newsDetail', params: {id: item.websiteNewsId}})
    },
    getCurrent(value) {
      this.currentPage = value
      this.initData(value)
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>