<template>
  <div class="socialResponsibility">
    <div>
      <p>{{ language.app.about.socialResponsibility.title }}</p>
      <div>
        <img src="../../../assets/images/about/she_hui_ze_ren1@2x.png" alt="">
        <p><span>{{ language.app.about.socialResponsibility.des[0] }}</span></p>
      </div>
      <div>
        <p><span>{{ language.app.about.socialResponsibility.des[1] }}</span></p >
        <img src="../../../assets/images/about/she_hui_ze_ren2@2x.png" alt="">
      </div>
      <div>
        <img src="../../../assets/images/about/she_hui_ze_ren3@2x.png" alt="">
        <p><span>{{ language.app.about.socialResponsibility.des[2] }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import {useLanguageStore} from '@/store/languageStore'
export default {
  name: 'socialResponsibility',
  data(){
    return{
      store:{},
      language:{},
    }
  },
  beforeMount() {
    this.getLanguage()
  },
  methods:{
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  }
}
</script>

<style>

</style>