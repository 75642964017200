<template>
  <a @click="goTop" class="goTop" v-show="isGoTopShow">
    <img src="../assets/images/goTop.svg" alt="">
  </a>
</template>

<script>
export default {
  name: "goTop",
  data(){
    return{
      isGoTopShow: false,
      winHeight:0
    }
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll)
  },
  methods:{
    handleScroll(e){
      this.winHeight =  document.documentElement.scrollTop+document.body.scrollTop;
      this.isGoTopShow = this.winHeight >= 1000
    },
    goTop(){
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = this.winHeight -= 50;
        if (this.winHeight <= 0) {
          clearInterval(timeTop); //清除定时器
        }
      }, 10);
    }
  },
  destroyed() {
    window.removeEventListener('scroll',this.handleScroll)
  }
}
</script>

<style scoped lang="less">
.goTop{
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  background-color: #fff;
  box-shadow: 0px 0px 1rem 0.2rem #ccc;
  img {
    width: 30%;
    height: 30%;
  }
}
@media screen and (min-width:750px) {
  .goTop{
    bottom: 10rem;
    right: 10rem;
    width: 5rem;
    height: 5rem;
    img {
      width: 50%;
      height: 50%;
    }
  }
}
</style>