<template>
  <div class="pages">
    <button class="pageBtn" style="margin-right:5rem" :disabled="this.currentPage == 1" @click="switchCurrentPage('pre')">
      <img src="../../assets/images/page/pre.svg" alt="">
    </button>
    <span v-if="pageTotal !== 1" class="news-pages-item" :class="{'news-pages-item-active': currentPage == 1}" @click="goPage(1)">1</span>
    <span v-if="preClipped" class="news-pages-item">...</span>
    <span v-for="index in pages" class="news-pages-item" :class="{'news-pages-item-active': currentPage == index}" @click="goPage(index)">{{ index }}</span>
    <span v-if="backClipped" class="news-pages-item">...</span>
    <span class="news-pages-item" style="margin-right: 0;" :class="{'news-pages-item-active': currentPage == pageTotal}" @click="goPage(pageTotal)">{{ pageTotal }}</span>
    <button class="pageBtn" style="margin-left: 5rem;" :disabled="this.currentPage == pageTotal" @click="switchCurrentPage('next')">
      <img src="../../assets/images/page/next.svg" alt="">
    </button>
  </div>
</template>

<script>
export default {
  props: {
    // 用于记录总页码，可由父组件传过来
    pageTotal: {
      type: Number,
      default: 1,
    },
    // 用于记录当前页数，这个与父组件进行数据交互来完成每一页的数据更新，所以我们只要改变currentPage的值来控制整个页面的数据即可
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // 用于判断省略号是否显示
      backClipped: true,
      preClipped: false,
    }
  },
  methods: {
    switchCurrentPage(type) {
      if (type == 'pre') {
        // 上一页
        let page = this.currentPage - 1
        this.$emit('getCurrent', page)
      }
      if (type == 'next') {
        // 下一页
        let page = this.currentPage + 1
        this.$emit('getCurrent', page)
      }
    },
    goPage(index) {
      // 跳转到相应页面
      if (index !== this.currentPage) {
        this.$emit('getCurrent', index)
      }

    },
  },
  computed: {
    // 使用计算属性来得到每次应该显示的页码
    pages() {
      let ret = [];
      let showPages = 8 // 显示的分页数
      if (this.pageTotal > showPages) { // 如果超过显示的总页数
        // 如果当前页大于三
        if (this.currentPage > 3) {
          if (this.currentPage == this.pageTotal) { // 如果当前页等于最后一页，则前面显示四个
            ret.push(this.currentPage - 4);
          }
          if (this.currentPage >= this.pageTotal - 1) {
            ret.push(this.currentPage - 3);
          }
          if (this.currentPage >= this.pageTotal - 2) {
            ret.push(this.currentPage - 2);
            ret.push(this.currentPage - 1);
          } else {
            ret.push(this.currentPage - 1);
          }
          this.preClipped = true;
        } else { // 如果没有大于3 则从2开始渲染
          this.preClipped = false;
          for (let i = 2; i < this.currentPage; i++) {
            ret.push(i);
          }
        }
        // 如果当前页不等于总页数并且 当前页不等于1
        if (this.currentPage !== this.pageTotal && this.currentPage !== 1) {
          ret.push(this.currentPage);
        }
        // 如果当前页 小于 总页数 - 2
        if (this.currentPage < this.pageTotal - 2) {
          if (this.currentPage > 1 && this.currentPage <= 1) {
            // 显示当前页码的后2个
            ret.push(this.currentPage + 1);
            ret.push(this.currentPage + 2);
          } else if ((this.currentPage > 1 && this.currentPage <= 2)) {
            for (let i = 1; i < 4; i++) {
              ret.push(this.currentPage + i);
            }
          } else if ((this.currentPage > 1 && this.currentPage == 3)) {
            ret.push(this.currentPage + 1);
            ret.push(this.currentPage + 2);
          } else if (this.currentPage <= 1) {
            for (let i = 1; i < 5; i++) {
              ret.push(this.currentPage + i);
            }
          } else {
            ret.push(this.currentPage + 1);
          }
          if (this.currentPage <= this.pageTotal - 3) {
            // 当前页与最后一页差距3以上时显示省略号
            this.backClipped = true;
          }
        } else {
          this.backClipped = false;
          for (let i = this.currentPage + 1; i < this.pageTotal; i++) {
            ret.push(i);
          }
        }
      } else {
        this.backClipped = false
        for (let i = 2; i < this.pageTotal; i++) {
          ret.push(i)
        }
      }
      // 返回整个页码组
      return ret;
    },
  },
};
</script>
<style scoped lang="less">
.pages {
  padding: 0 0 1rem;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 14.2rem;
  .pageBtn {
    width: 3.5rem;
    height: 3.5rem;
    background-color: transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      height: 60%;
    }
    &:hover {
      background-color: #F1F1F1;
    }
  }
  .news-pages-item, .news-pages-item-active {
    width: 3.5rem;
    height: 3.5rem;
    text-align: center;
    font-size: 2rem;
    line-height: 3.5rem !important;
    border-radius: 100%;
    margin-right: 3.8rem;
    cursor: pointer;
  }
  .news-pages-item:hover {
    background-color: #F1F1F1;
  }
  .news-pages-item-active, .news-pages-item-active:hover {
    background-color: #222222;
    color: #fff !important;
  }
}
</style>
