import axios from 'axios'

const request = axios.create({
    baseURL:process.env.VUE_APP_BASE_API,
    // baseURL:'https://www.zhipiganqing.com/ntms',
    timeout: 5000 * 4,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
})

request.interceptors.request.use(req=>req)
request.interceptors.response.use(res=>res.data,err=>Promise.reject(new Error('fail')))

export default request