import {defineStore} from 'pinia'
import {chinese, english} from "@/utils/language";

const lang = localStorage.getItem('language')

export const useLanguageStore = defineStore('language',{
    state(){
        return {
            languageObj: lang == 'EN' ? english : chinese,
        }
    },
    actions:{
        setLanguage(type){
            localStorage.setItem('language',type)
            this.languageObj = type == 'chinese' ? chinese : english
            this.languageObj.app.home.banner.bottomText = window.innerWidth > 750 ? '鼠标滑动查看更多' : '向上滑动查看更多'
            this.languageObj.app.download.button.ios.text = window.innerWidth <= 750 ? '' : type == 'chinese' ? '下载应用，请到' :''
        }
    }
})