<template>
  <div class="newDetail" id="newDetail" ref="newDetail">
    <div class='title'>
      <div>
        <div id="back" class="back" @click="goBack()"><img src="../../assets/images/goBack.svg" alt="">{{ language.app.common.jobAndNewDetailBtn }}</div>
        <div class="title">{{ detail.title }}</div>
        <div class="time">{{ detail.createTime }}</div>
      </div>
    </div>
    <div class='body ql-editor' v-html="detail.content">
    </div>
    <!--  返回顶部  -->
    <goTop></goTop>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {selectNewsPageById} from '../apiJs/newsApi'
import goTop from "@/components/goTop";
import eventBus from "@/eventBus";
import {useLanguageStore} from "@/store/languageStore";

export default {
  name: 'newsDetail',
  components:{goTop},
  data() {
    return {
      detail: {},
      store:{},
      language:{},
    }
  },
  async mounted() {
    // console.log(this.$route.params.id);
    await selectNewsPageById({websiteNewsId: this.$route.params.id}).then(res => {
      this.detail = res.data
    })
  },
  beforeMount() {
    this.getLanguage()
  },
  methods:{
    goBack(){
      this.$router.push({name: 'about',params: {tabName: "newTrends"}})
      eventBus.emit('navName','about')
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  }
}
</script>

<style lang="less">
.newDetail {
  position: relative;
  >.title {
    background-color: #F7F8FA;
    padding: 8rem 0 1rem 5%;
    div {
      min-width: 30rem;
      //margin: 0 auto;
      .back {
        display: flex;
        color: #424242;
        font-size: 1.2rem;
        cursor: pointer;
        opacity: .5;
        img {
          width: .7rem;
          margin-right: .5rem;
        }
      }
      .title {
        color: #1D1D1D;
        font-size: 1.8rem;
        margin-top: 1.5rem;
      }
      .time{
        color: #8A8A8A;
        font-size: 1.2rem;
        margin-top: .5rem;
      }
    }
  }
  .body{
    width:100%;
    >p{
      padding-top: 1rem!important;
    }
  }
  .ql-editor {
    font-size: 1.2rem;
    padding: 2rem 5% 0;
    img{
      min-width: 30rem;
      width: 100%;
    }
  }
}
@media screen and (min-width:750px){
  .newDetail{
    >.title{
      padding: 12rem 0 8rem 10%;
      div{
        max-width: 1440px;
        .back {
          font-size: 1.8rem;
          img {
            width: .8rem;
            margin-right: 1rem;
          }
        }
        .title {
          font-size: 3.5rem;
          margin-top: 2.5rem;
        }
        .time{
          font-size: 1.6rem;
          margin-top: .5rem;
        }
      }
    }
    .ql-editor {
      font-size: 1.6rem;
      padding: 4rem 10% 10rem;
      img{
        min-width: auto;
        width: auto;
      }
    }
  }
}
</style>