<template>
  <div class="page-download">
    <div class="page-download-content">
      <div class="page-download-content-box">
        <h1>{{ language.app.download.title }}</h1>
        <div class="page-download-content-btnBar">
            <button>
              <img src="../assets/images/download/ios.svg" alt="">
              <div style="width:55%">
                <i>{{ language.app.download.button.ios.text }}</i>
                <span>APP Store</span>
              </div>
            </button>
            <button>
              <img src="../assets/images/download/android.svg" alt="">
              <span>Android</span>
            </button>
          </div>
        <div class="page-download-content-imgBar">
            <img src="../assets/images/download/iosCode.png" alt="">
            <img src="../assets/images/download/androidCode.png" alt="">
          </div>
        <img src="../assets/images/download/star@2x.png" alt="">
        <img src="../assets/images/download/girl@2x.png" alt="">
        <img src="../assets/images/download/boy@2x.png" alt="">
      </div>
    </div>
    <div class="page-download-introduce">
         <div class="page-download-introduce-one">
           <div class="page-download-introduce-text">
             <p>{{ language.app.download.content.left.title }}</p>
             <span>{{ language.app.download.content.left.des }}</span>
             <h1>{{ language.app.download.content.left.subTitle }}</h1>
           </div>
           <div class="page-download-introduce-img">
             <div class="page-download-introduce-img-border">
               <img src="../assets/images/download/phoneBorderS@2x.png" alt="">
               <div class="active-swiper-box-img">
                 <img :src="swiperOneImg" alt="">
               </div>
             </div>
             <div class="page-download-introduce-img-swiper">
              <button class="swiperPrevBtn"><img src="../assets/images/download/moreLeft.svg" alt=""></button>
               <Swiper
                   :modules="modules"
                   :slides-per-view="4"
                   :space-between="25"
                   loop
                   :autoplay="{delay: 3000, disableOnInteraction: false}"
                   :navigation="{ nextEl:'.swiperNextBtn', prevEl:'.swiperPrevBtn' }"
                   @swiper="onSwiper"
                   @slideChange="onSlideChange('O')"
               >
                 <SwiperSlide @click="handleSwiperSlide('O',index)" v-for="(item,index) in swiperImagesArr">
                   <img :class="{'swiper-item-active': swiperOneImgActive == index}" :src="item" alt="">
                 </SwiperSlide>
               </Swiper>
               <button class="swiperNextBtn"><img src="../assets/images/download/moreRight.svg" alt=""></button>
             </div>
            </div>
             <div class="page-download-introduce-img-ml">
               <swiper :scrollbar="{hide: true}"
                       :modules="modulesMl" loop
                       :autoplay="{delay: 3000, disableOnInteraction: false,disableOnInteraction: false}"
                       class="active-swiper-box-img">
                 <swiper-slide v-for="item in swiperImagesArr">
                   <img :src="item" alt="">
                 </swiper-slide>
               </swiper>
               <p>{{ language.app.download.content.mlTipsText }}</p>
           </div>
         </div>
         <div class="page-download-introduce-two">
           <div class="page-download-introduce-img">
             <div class="page-download-introduce-img-border">
               <img src="../assets/images/download/phoneBorderS@2x.png" alt="">
               <div class="active-swiper-box-img">
                <img :src="swiperTwoImg" alt="">
               </div>
             </div>
             <div class="page-download-introduce-img-swiper">
               <button class="swiperPrevBtnT"><img src="../assets/images/download/moreLeft.svg" alt=""></button>
               <Swiper
                   :modules="modules"
                   :slides-per-view="4"
                   :space-between="25"
                   loop
                   :autoplay="{delay: 3000, disableOnInteraction: false}"
                   :navigation="{ nextEl:'.swiperNextBtnT', prevEl:'.swiperPrevBtnT'}"
                   @swiper="onSwiperT"
                   @slideChange="onSlideChange('T')">
                 <SwiperSlide  @click="handleSwiperSlide('T',index)"  v-for="(item,index) in swiperImagesArrT">
                   <img :class="{'swiper-item-active':swiperTwoImgActive == index}" :src="item" alt="">
                 </SwiperSlide>
               </Swiper>
               <button class="swiperNextBtnT"><img src="../assets/images/download/moreRight.svg" alt=""></button>
             </div>
           </div>
           <div class="page-download-introduce-text">
             <p>{{ language.app.download.content.right.title }}</p>
             <span>{{ language.app.download.content.right.des }}</span>
             <h1>{{ language.app.download.content.right.subTitle }}</h1>
           </div>
           <div class="page-download-introduce-img-ml">
             <swiper :scrollbar="{hide: true}"
                     :modules="modulesMl" loop
                     :autoplay="{delay: 3000, disableOnInteraction: false,disableOnInteraction: false}"
                     class="active-swiper-box-img">
               <swiper-slide v-for="item in swiperImagesArrT">
                 <img :src="item" alt="">
               </swiper-slide>
             </swiper>
             <p>{{ language.app.download.content.mlTipsText }}</p>
           </div>
         </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/pages/download.less"
import { Navigation, Autoplay,Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
//司机
import delivering from '@/assets/images/download/driver/delivering.png'
import carSource from '@/assets/images/download/driver/carSource.png'
import confirmReceipt from '@/assets/images/download/driver/confirmReceipt.png'
import orderStatus from '@/assets/images/download/driver/orderStatus.png'
// 货主
import goodSource from '@/assets/images/download/shipper/goodSource.png'
import map from '@/assets/images/download/shipper/map.png'
import goodsDetail from '@/assets/images/download/shipper/goodsDetail.png'
import cash from '@/assets/images/download/shipper/cash.png'
import raiseCar from '@/assets/images/download/shipper/raiseCar.png'
import seekTenders from '@/assets/images/download/shipper/seekTenders.png'
import smallVideo from '@/assets/images/download/shipper/smallVideo.png'
import {useLanguageStore} from "@/store/languageStore";
export default {
  name: "download",
  components:{Swiper, SwiperSlide},
  data(){
    return {
      modules:[Navigation, Autoplay],
      modulesMl:[Scrollbar,Autoplay],
      swiperElement:null,
      swiperElementT:null,
      swiperImagesArr:[delivering, carSource, confirmReceipt, orderStatus],
      swiperImagesArrT:[goodSource, map, goodsDetail, cash, raiseCar, seekTenders, smallVideo],
      swiperOneImg:'',
      swiperTwoImg:'',
      swiperOneImgActive:0,
      swiperTwoImgActive:0,
      swiperOneImgActiveMl:0,
      swiperTwoImgActiveMl:0,
      store:{},
      language:{},
    }
  },
  beforeMount() {
    this.getLanguage()
  },
  mounted() {
    this.language.app.download.button.ios.text = window.innerWidth <= 750 ? '' : this.language.app.download.button.ios.text
    this.swiperOneImg = this.swiperImagesArr[0]
    this.swiperTwoImg = this.swiperImagesArrT[0]
  },
  methods:{
    onSwiper(swiper){
      this.swiperElement = swiper
    },
    onSwiperT(swiper){
      this.swiperElementT = swiper
    },
    onSlideChange(type){
      if(type == 'O'){
        if(this.swiperElement != null){
          this.swiperOneImgActive = this.swiperElement.realIndex
          this.swiperOneImg = this.swiperImagesArr[this.swiperElement.realIndex]
        }
      }
      if(type == 'T'){
        if(this.swiperElementT != null){
          this.swiperTwoImgActive = this.swiperElementT.realIndex
          this.swiperTwoImg = this.swiperImagesArrT[this.swiperElementT.realIndex]
        }
      }
    },
    handleSwiperSlide(type,index){
      if(type == 'O'){
        this.swiperOneImgActive = index
        this.swiperOneImg = this.swiperImagesArr[index]
      }
      if(type == 'T'){
        this.swiperTwoImgActive = index
        this.swiperTwoImg = this.swiperImagesArrT[index]
      }
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  }
}
</script>

<style scoped>

</style>