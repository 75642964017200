import {createRouter, createWebHistory, createWebHashHistory, createMemoryHistory, RouteRecordRaw} from 'vue-router'
import home from "@/views/home";
import product from "@/views/product";
import about from "@/views/about/about";
import download from "@/views/download";
import newsDetail from '@/views/news/newsDetail.vue'
import jobsList from '@/views/jobs/jobsList.vue';
import jobsDetail from '@/views/jobs/jobsDetail.vue';
import eventBus from "@/eventBus";

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/App.vue'),
  },
  {
    name: 'home',
    meta:{title:"首页"},
    path: '/home',
    component: home
  },
  {
    name: 'product',
    path: '/product',
    meta:{title:"产品中心"},
    component: product
  },
  {
    name: 'about',
    meta:{title:"关于我们"},
    path: '/tabs/about/:tabName',
    component: about,
  },
  {
    name: 'download',
    meta:{title:"下载app"},
    path: '/download',
    component: download
  },
  {
    path: '/tabs/newsDetail/:id',
    meta:{title:"新闻详情"},
    name: 'newsDetail',
    component: newsDetail
  },
  {
    path: '/jobs/jobsList',
    meta:{title:"招聘列表"},
    name: 'jobsList',
    component: jobsList
  },
  {
    path: '/jobs/jobsDetail',
    meta:{title:"招聘详情"},
    name: 'jobsDetail',
    component: jobsDetail
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async(to, from, next) => {
  eventBus.emit('navName',to.name)
  next()
  eventBus.emit('navHide')
  document.title = `智猪科技官方网站`
  document.getElementById('foot').style.display="flex";
  document.documentElement.scrollTop = 0
})

//导出router
export default router