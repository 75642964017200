import request from "@/utils/request";

//新闻动态列表
export const getNewsPage = (params) => request({
  url:'tms/website/news/page',
  method:'post',
  data:params
})
// 详情
export const selectNewsPageById = (params) => request({
  url:'tms/website/news/selectById',method:'post',data:params
})