<template>
  <div class="page-product">
    <full-page :options="options" id="fullPage" ref="fullPage">
      <div class="scroll">
        <div class="body-content1" id="a">
          <div class="title">
            <p>{{ language.app.product.bodyContent1.title }}</p>
            <div>
              {{language.app.product.bodyContent1.des}}
            </div>
          </div>
          <div class="body">
            <div class="body-top">
              <div>
                <div class="left"></div>
                <p>{{language.app.product.bodyContent1.bodyTitle}}</p>
                <div class="right"></div>
              </div>
              <div>
                <div>
                  <div style="width: 217px;text-align: right">
                    <img src="../assets/images/product/product_xingyun-driver@2x.png"
                         style="width: 70px;margin-right: 6rem">
                  </div>
                  <div>
                    <img src="../assets/images/product/product_xingyun-right@2x.png" style="width: 24px">
                  </div>
                  <div>
                    <img src="../assets/images/product/product_xingyun-AI@2x.png" style="width: 385px">
                  </div>
                  <div>
                    <img src="../assets/images/product/product_xingyun-right@2x.png" style="width: 24px">
                  </div>
                  <div>
                    <img src="../assets/images/product/product_xingyun-goods@2x.png" style="width: 217px">
                  </div>
                </div>
              </div>
            </div>
            <div class="body-foot">
              <div v-for="item in language.app.product.bodyContent1.bodyFoot">
                <p>{{ item.title }}</p>
                <p>{{ item.content }}</p>
              </div>
<!--              <div>-->
<!--                <p>用户画像体系</p>-->
<!--                <p>平台用户体系的搭建，盘活平台运力资源，通过个人体系不断充实以及更新用户画像数据，为用户召回，活动运营，车货匹配提供了强有力的支撑。</p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <p>智能推荐算法</p>-->
<!--                <p>通过算法为货源匹配合适的司机，通过为司机推荐货源提升货源的成交率，相对于线下联系的单程沟通，大大提升了货源匹配司机的效率。</p>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="scroll">
        <div class="body-content2" id="b">
          <div class="animate-content2 fadeOut">
            <div class="title">
              <p>{{ language.app.product.bodyContent2.title }}</p>
            </div>
            <div class="body">
              <div class="left">
                <p style="font-size: 2.2rem;color: #1860EF;font-family: DingTalk-JinBuTi, DingTalk">{{ language.app.product.bodyContent2.bodyLeft.c1 }}</p>
                <p style="font-size: 1.6rem;margin-top: 3rem">
                  {{ language.app.product.bodyContent2.bodyLeft.c2 }}
                </p>
                <p style="color: #585858;font-size: 1.4rem;margin-top: 3rem">
                  {{ language.app.product.bodyContent2.bodyLeft.c3 }}
                </p>
                <div class="left-bottom">
                  <div>
                    <div><img src="../assets/images/product/youshi_youzhi.svg">
                      <p>{{ language.app.product.bodyContent2.bodyLeft.leftBottom[0].title }}</p></div>
                    <div>{{ language.app.product.bodyContent2.bodyLeft.leftBottom[0].content }}</div>
                  </div>
                  <div style="width: 6rem">
                  </div>
                  <div>
                    <div><img src="../assets/images/product/shuju.svg">
                      <p>{{ language.app.product.bodyContent2.bodyLeft.leftBottom[1].title }}</p></div>
                    <div>{{ language.app.product.bodyContent2.bodyLeft.leftBottom[1].content }}</div>
                  </div>
                </div>
              </div>
              <div class="right">
                <p style="font-size: 2.2rem;color: #1860EF;font-family: DingTalk-JinBuTi, DingTalk">{{ language.app.product.bodyContent2.bodyRight.title }}</p>
                <p style="color: #585858;font-size: 1.4rem;margin-top: 3rem">{{ language.app.product.bodyContent2.bodyRight.content }}</p>
                <div class="right-bottom">
                  <div>
                    <div>
                      <img src="../assets/images/product/renyuan-.svg">
                    </div>
                    <div>
                      <div v-for="item in language.app.product.bodyContent2.bodyRight.rightBottom.tagOne">{{ item }}</div>
<!--                      <div>驾驶习惯</div>-->
<!--                      <div>行为监控</div>-->
<!--                      <div>实时报备</div>-->
<!--                      <div>司机画像</div>-->
<!--                      <div>司机招聘</div>-->
                    </div>
                  </div>
                  <div>
                    <div>
                      <img src="../assets/images/product/huoche.svg">
                    </div>
                    <div>
                      <div v-for="item in language.app.product.bodyContent2.bodyRight.rightBottom.tagTwo">{{ item }}</div>
<!--                      <div>油耗管理</div>-->
<!--                      <div>任务关联</div>-->
<!--                      <div>违章及年审</div>-->
<!--                      <div>事故及违章</div>-->
<!--                      <div>保险及租赁</div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll">
        <div class="body-content3" id="c">
          <div class="animate-content3 fadeOut">
            <div class="title">
              <p>{{ language.app.product.bodyContent3.title }}</p>
              <div>{{ language.app.product.bodyContent3.des }}</div>
              <img :src="language.app.product.bodyContent3.img">
            </div>
          </div>
        </div>
      </div>
      <div class="scroll">
        <div class="body-content4" id="d">
          <div class="animate-content4 fadeOut">
            <div class="title">
              <p>{{ language.app.product.bodyContent4.title }}</p>
              <div>{{ language.app.product.bodyContent4.des }}</div>
            </div>
            <div class="body">
              <div class="left">
                <div class="left-top">
                  <ul :style="{transform: 'translateX(-'+left+'px)','transition':' all 0.4s ease-in-out'}"
                      id="calcWidth">
                    <li v-for="(item,index) in language.app.product.bodyContent4.body.left" @click="ev=>{tab(item.name,index,ev)}">
                      <a :class="{active:nameFlag == item.name}">{{ item.name }}</a>
                    </li>
                  </ul>
                </div>
                <div class="left-bottom">
                  <transition-group name="tabFade">
                    <div v-for="item in language.app.product.bodyContent4.body.left" :key="item.name" v-show="item.name == nameFlag">
                      {{ item.content }}
                    </div>
                  </transition-group>
                </div>
              </div>
              <div class="right">
                <div>
                  <img src="../assets/images/product/radois.svg">
                  <div class="icon1">
                    <div>
                      <img src="../assets/images/product/yunjisuan.svg" style="width: 4rem">
                      <p style="margin-top: 1rem">{{ language.app.product.bodyContent4.body.right.title }}</p>
                    </div>
                  </div>
                  <div class="icon2" @click="tab(language.app.product.bodyContent4.body.right.content[0].name,0)" :class="{active:nameFlag==language.app.product.bodyContent4.body.right.content[0].name}">
                    <p>{{ language.app.product.bodyContent4.body.right.content[0].name }}</p>
                  </div>
                  <div class="icon3" @click="tab(language.app.product.bodyContent4.body.right.content[1].name,6)" :class="{active:nameFlag==language.app.product.bodyContent4.body.right.content[1].name}">
                    <p>{{ language.app.product.bodyContent4.body.right.content[1].name }}</p>
                  </div>
                  <div class="icon4" @click="tab(language.app.product.bodyContent4.body.right.content[2].name,4)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[2].name}">
                    <p>{{ language.app.product.bodyContent4.body.right.content[2].name }}</p>
                  </div>
                  <div class="icon5" @click="tab(language.app.product.bodyContent4.body.right.content[3].name,3)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[3].name}">
                    <p>{{ language.app.product.bodyContent4.body.right.content[3].name }}</p>
                  </div>
                  <div class="icon6" @click="tab(language.app.product.bodyContent4.body.right.content[4].name,2)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[4].name}">
                    <p>{{ language.app.product.bodyContent4.body.right.content[4].name }}</p>
                  </div>
                  <div class="icon7" @click="tab(language.app.product.bodyContent4.body.right.content[5].name,7)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[5].name}">
                    <p>{{ language.app.product.bodyContent4.body.right.content[5].name }}</p>
                  </div>
                  <div class="icon8" @click="tab(language.app.product.bodyContent4.body.right.content[6].name,5)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[6].name}">
                    <p>{{ language.app.product.bodyContent4.body.right.content[6].name }}</p>
                  </div>
                  <div class="icon9" @click="tab(language.app.product.bodyContent4.body.right.content[7].nameFlag,1)" :class="{active:nameFlag==language.app.product.bodyContent4.body.right.content[7].nameFlag}">
                    <p v-html="language.app.product.bodyContent4.body.right.content[7].name"></p>
                  </div>
                </div>
              </div>
              <div class="clear-fix"></div>
            </div>
          </div>
        </div>
        <div class="foot">
          <div class="foot-left">
            <img src="../assets/images/home/logoW.svg">
            <p style="font-size: 1.4rem;margin-top: 0.6rem;">{{ language.app.common.foot.footLeft.one }}</p>
            <p style="font-size: 1.8rem;margin-top: 10rem;color: #A6A6A6">{{ language.app.common.foot.footLeft.two }}</p>
            <p style="font-size: 2.4rem;margin-top: 1rem;">{{ language.app.common.foot.footLeft.three }}</p>
            <p style="font-size: 1.4rem;margin-top: 1rem;color: #A6A6A6">{{ language.app.common.foot.footLeft.four }}</p>
          </div>
          <div class="foot-mid">
            <div>
              <div v-for="(item,index) in language.app.common.footerCodeList">
                <img :class="{opacityImg:(footerCodeActive != index && footerCodeActive  != 3)}" @mouseenter="footerCodeActive = index" @mouseleave="footerCodeActive=3" :src="item.img">
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div class="foot-right">
            <p @click="goToPage(item)" v-for="item in language.app.common.foot.footRightTextList">{{ item.name }}</p>
          </div>
          <p class="copy-right">{{ language.app.common.foot.copyRight }}</p>
        </div>
      </div>
    </full-page>
    <div class="mlProduct">
      <div class="body-content1-ml" id="mlA">
        <div class="title">
          <p>{{ language.app.product.bodyContent1.title }}</p>
          <div>
            {{language.app.product.bodyContent1.des}}
          </div>
        </div>
        <div class="body">
          <div class="body-top">
            <div>
              <div class="left"></div>
              <p>{{language.app.product.bodyContent1.bodyTitle}}</p>
              <div class="right"></div>
            </div>
            <div>
              <div>
                <div>
                  <img src="../assets/images/product/product_xingyun-driver@2x.png" style="width: 5rem;height: 10rem">
                </div>
                <div>
                  <img src="../assets/images/product/product_xingyun-down@2x.png" style="width: 8px">
                </div>
                <div>
                  <img src="../assets/images/product/product_xingyun-AI@2x.png" style="width: 270px">
                </div>
                <div>
                  <img src="../assets/images/product/product_xingyun-down@2x.png" style="width: 8px">
                </div>
                <div>
                  <img src="../assets/images/product/product_xingyun-goods-ml@2x.png" style="width: 185px">
                </div>
              </div>
            </div>
          </div>
          <div class="body-foot">
            <div v-for="item in language.app.product.bodyContent1.bodyFoot">
              <p>{{ item.title }}</p>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="body-content2-ml" id="mlB">
        <div class="animate-content2">
          <div class="title">
            <p>{{ language.app.product.bodyContent2.title }}</p>
          </div>
          <div class="body">
            <div class="left">
              <p style="font-size: 1.6rem;color: #1860EF;font-family: DingTalk-JinBuTi, DingTalk">{{ language.app.product.bodyContent2.bodyLeft.c1 }}</p>
              <p style="font-size: 1.4rem;margin-top: 1rem;font-weight: 600">
                {{ language.app.product.bodyContent2.bodyLeft.c2 }}
              </p>
              <p style="color: #585858;font-size: 1.2rem;margin-top: 1rem">
                {{ language.app.product.bodyContent2.bodyLeft.c3 }}
              </p>
              <div class="left-bottom">
                <div>
                  <div><img src="../assets/images/product/youshi_youzhi.svg">
                    <p>{{ language.app.product.bodyContent2.bodyLeft.leftBottom[0].title }}</p></div>
                  <div>{{ language.app.product.bodyContent2.bodyLeft.leftBottom[0].content }}</div>
                </div>
                <div style="width: 6rem">
                </div>
                <div>
                  <div><img src="../assets/images/product/shuju.svg">
                    <p>{{ language.app.product.bodyContent2.bodyLeft.leftBottom[1].title }}</p></div>
                  <div>{{ language.app.product.bodyContent2.bodyLeft.leftBottom[1].content }}</div>
                </div>
              </div>
            </div>
            <div class="right">
              <p style="font-size: 1.6rem;color: #1860EF;font-family: DingTalk-JinBuTi, DingTalk">{{ language.app.product.bodyContent2.bodyRight.title }}</p>
              <p style="color: #585858;font-size: 1.2rem;margin-top: 1rem">{{ language.app.product.bodyContent2.bodyRight.content }}</p>
              <div class="right-bottom">
                <div>
                  <div>
                    <img src="../assets/images/product/renyuan-.svg">
                  </div>
                  <div>
                    <div v-for="item in language.app.product.bodyContent2.bodyRight.rightBottom.tagOne">{{ item }}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="../assets/images/product/huoche.svg">
                  </div>
                  <div>
                    <div v-for="item in language.app.product.bodyContent2.bodyRight.rightBottom.tagTwo">{{ item }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="body-content3-ml" id="mlC">
        <div class="animate-content3 fadeOut">
          <div class="title">
            <p>{{ language.app.product.bodyContent3.title }}</p>
            <div>{{ language.app.product.bodyContent3.des }}</div>
            <img :src="language.app.product.bodyContent3.img">
          </div>
        </div>
      </div>

      <div class="body-content4-ml" id="mlD">
        <div class="animate-content4 fadeOut">
          <div class="title">
            <p>{{ language.app.product.bodyContent4.title }}</p>
            <div>{{ language.app.product.bodyContent4.des }}</div>
          </div>
          <div class="body">
            <div class="left">
              <div class="left-top">
                <ul :style="{transform: 'translateX(-'+left+'px)','transition':' all 0.4s ease-in-out'}"
                    id="mlCalcWidth">
                  <li v-for="(item,index) in language.app.product.bodyContent4.body.left" @click="ev=>{tab(item.name,index,ev)}">
                    <a :class="{active:nameFlag == item.name}">{{ item.name }}</a>
                  </li>
                </ul>
              </div>
              <div class="left-bottom">
                <transition-group name="tabFade">
                  <div v-for="item in language.app.product.bodyContent4.body.left" :key="item.name" v-show="item.name == nameFlag">
                    {{ item.content }}
                  </div>
                </transition-group>
              </div>
            </div>
            <div class="right">
              <div>
                <img src="../assets/images/product/radois.svg">
                <div class="icon1">
                  <div>
                    <img src="../assets/images/product/yunjisuan.svg" style="width: 3rem">
                    <p style="margin-top: 1rem">{{ language.app.product.bodyContent4.body.right.title }}</p>
                  </div>
                </div>
                <div class="icon2" @click="tab(language.app.product.bodyContent4.body.right.content[0].name,0)" :class="{active:nameFlag==language.app.product.bodyContent4.body.right.content[0].name}">
                  <p>{{ language.app.product.bodyContent4.body.right.content[0].name }}</p>
                </div>
                <div class="icon3" @click="tab(language.app.product.bodyContent4.body.right.content[1].name,6)" :class="{active:nameFlag==language.app.product.bodyContent4.body.right.content[1].name}">
                  <p>{{ language.app.product.bodyContent4.body.right.content[1].name }}</p>
                </div>
                <div class="icon4" @click="tab(language.app.product.bodyContent4.body.right.content[2].name,4)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[2].name}">
                  <p>{{ language.app.product.bodyContent4.body.right.content[2].name }}</p>
                </div>
                <div class="icon5" @click="tab(language.app.product.bodyContent4.body.right.content[3].name,3)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[3].name}">
                  <p>{{ language.app.product.bodyContent4.body.right.content[3].name }}</p>
                </div>
                <div class="icon6" @click="tab(language.app.product.bodyContent4.body.right.content[4].name,2)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[4].name}">
                  <p>{{ language.app.product.bodyContent4.body.right.content[4].name }}</p>
                </div>
                <div class="icon7" @click="tab(language.app.product.bodyContent4.body.right.content[5].name,7)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[5].name}">
                  <p>{{ language.app.product.bodyContent4.body.right.content[5].name }}</p>
                </div>
                <div class="icon8" @click="tab(language.app.product.bodyContent4.body.right.content[6].name,5)" :class="{activeRed:nameFlag==language.app.product.bodyContent4.body.right.content[6].name}">
                  <p>{{ language.app.product.bodyContent4.body.right.content[6].name }}</p>
                </div>
                <div class="icon9" @click="tab(language.app.product.bodyContent4.body.right.content[7].nameFlag,1)" :class="{active:nameFlag==language.app.product.bodyContent4.body.right.content[7].nameFlag}">
                  <p v-html="language.app.product.bodyContent4.body.right.content[7].name"></p>
                </div>
              </div>
            </div>
            <div class="clear-fix"></div>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="foot-left">
          <img src="../../src/assets/images/home/logoW.svg"/>
          <p style="font-size: 1.4rem;margin: 0.6rem 0 10rem;">{{ language.app.common.foot.footLeft.one }}</p>
          <p style="font-size: 1.8rem;color: #A6A6A6">{{ language.app.common.foot.footLeft.two }}</p>
          <p style="font-size: 2.4rem;margin-top: 1rem;">{{ language.app.common.foot.footLeft.three }}</p>
          <p style="font-size: 1.4rem;margin-top: 1rem;color: #A6A6A6">{{ language.app.common.foot.footLeft.four }}</p>
        </div>
        <div class="foot-mid">
          <div>
            <div v-for="(item,index) in language.app.common.footerCodeList">
              <img :class="{opacityImg:footerCodeActive != index && footerCodeActive  != 3}" @mouseenter="footerCodeActive = index" @mouseleave="footerCodeActive=3" :src="item.img">
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="foot-right">
          <p @click="goToPage(item)" v-for="item in language.app.common.footRightTextList">{{ item.name }}</p>
        </div>
        <p class="copy-right">{{ language.app.common.foot.copyRight }}</p>
      </div>
    </div>
    <goTop></goTop>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
import "../assets/css/pages/product.less"
import "../assets/css/pages/foot.less"
import goTop from "@/components/goTop";
import {useLanguageStore} from "@/store/languageStore";

export default {
  name: "product",
  components: {goTop},
  data() {
    return {
      nameFlag: '维修保养',
      left: 0,
      options: {
        sectionSelector: ".scroll",        //section选择器
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        navigation: true, //是否显示导航，默认为false
        navigationPosition: 'right', //导航小圆点的位置
        autoScrolling:true,
        scrollBar: false,
        keyboardScrolling: false, //是否可以使用键盘方向键导航，默认为true
        continuousVertical: false, /// 是否循环滚动，默认为false。如果设置为true，则页面会循环滚动，而不像loopTop或loopBottom那样出现跳动，注意这个属性和loopTop、loopBottom不兼容和，不要同时设置
        menu: '#menu',
        onLeave: (index, nextIndex, direction)=>{
          this.numDataCall(nextIndex.index)
        }
      },
      footerCodeActive:3,
      store:{},
      language:{}
    }
  },
  beforeMount() {
    this.getLanguage()
    this.nameFlag = this.language.app.product.bodyContent4.body.right.content[0].name
  },
  beforeUnmount() {
    //组件销毁前要解绑事件
    eventBus.all.delete("scrollTo");
    eventBus.all.delete("tabTo");
  },
  mounted() {
    fullpage_api.moveTo(1)
    document.onkeydown = (event)=>{
      if(event.keyCode == '17' || event.keyCode == '91'){
        this.options.scrollBar = true
        this.options.autoScrolling = false
      }
    }
    document.onkeyup = (event)=>{
      this.options.scrollBar = false
      this.options.autoScrolling = true
    }
    document.getElementById('foot').style.display="none";
    eventBus.on('scrollTo', (id) => {
      this.goScroll(id)
    })
    eventBus.on('tabTo', (obj) => {
      this.tab(obj.nameFlag, obj.index)
    })
  },
  methods: {
    goScroll(id) {
      if(window.innerWidth > 750){
        let index = null
        index = id =='#a' ? 0:id == '#b' ? 1: id == '#c' ? 2 : 3
        fullpage_api.moveTo(index + 1)
      }else{
        document.querySelector(id).scrollIntoView({behavior: 'smooth', instantblock: 'start'})
      }
    },
    tab(nameFlag, index) {
      this.nameFlag = nameFlag
      let dom = window.innerWidth > 750 ? 'calcWidth' : 'mlCalcWidth'
      if (index > 1) {
        let left = 0
        for (let i = 0; i < document.getElementById(dom).children.length; i++) {
          if (i <= index - 2) {
            left += document.getElementById(dom).children[i].clientWidth
          }
        }
        this.left = left
      } else {
        this.left = 0
      }
    },
    numDataCall(index){
      let c2 = document.querySelector('.page-product').querySelector('.body-content2').getElementsByClassName('animate-content2')[0]
      let c3 = document.querySelector('.page-product').querySelector('.body-content3').getElementsByClassName('animate-content3')[0]
      let c4 = document.querySelector('.page-product').querySelector('.body-content4').getElementsByClassName('animate-content4')[0]
      if(index == 1){
        c2.classList.add('fadeIn')
      } else {
        c2.classList.remove('fadeIn')
      }
      if (index == 2) {
        c3.classList.add('fadeIn')
      } else {
        c3.classList.remove('fadeIn')
      }
      if (index == 3) {
        c4.classList.add('fadeIn')
      } else {
        c4.classList.remove('fadeIn')
      }
    },
    goToPage(item){
      if(item.key == 'about'){
        this.$router.push({name: 'about',params: {tabName: item.tabName ? item.tabName : 'companyProfile'}})
        eventBus.emit('navName','about')
      }else{
        this.$router.push({name:item.key})
        eventBus.emit('navName','product')
      }
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
        this.nameFlag = this.language.app.product.bodyContent4.body.right.content[0].name
      })
    },
  }
}
</script>

<style scoped>

</style>