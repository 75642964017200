<template>
  <div>
    <div v-show="!active" @mouseenter="active = true" style="width: 100%;height: 2rem;z-index:1;position: fixed;top: 0;"></div>
    <nav id="nav" @mouseenter="active = true" :class="{active:active}">
      <img @click="goPage({name:'首页',key:'home'})" src="../src/assets/images/logo.svg" class="pull-left nav-logo">
      <div @click="isNavMenuShow = true" class="navbar-btn pull-right clear-fix">
        <img src="../src/assets/images/ml-home-menu@2x.png" alt="">
      </div>
      <!--   pc菜单   -->
      <div class="navbar-box pull-right clear-fix">
        <ul class="navbar-nav">
          <li v-for="item in language.app.nav.navList"><a @click="goPage(item)" @mouseenter="handleNavMouse('m',item)" @mouseleave="handleNavMouse('l',item)" :class="{active:componentName == item.key ||mouseName == item.key }">{{ item.name }}
            <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
              <div class="nav-download-code" v-show="item.key == 'download' && mouseName == item.key">
                <div v-for="item in  language.app.common.footerCodeList"><img :src="item.img" alt=""><p>
                  {{ item.name }}</p></div>
              </div>
            </transition>
          </a></li>
        </ul>
        <div @click="handleChangeLanguage" class="language">
          <span>{{this.language.app.nav.language}}</span>
        </div>
      </div>
      <!--   h5菜单   -->
      <transition :duration="100" enter-active-class="animate__animated animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutRight">
        <div v-show="isNavMenuShow"  @click.stop="isNavMenuShow = false" class="nav-menu">
          <div class="pull-right">
            <div class="clear-menu" @click="isNavMenuShow = false">
              <img src="../src/assets/images/ml-home-menu-cancel@2x.png" alt="">
            </div>
              <ul class="nav-menu-item">
                <li v-for="item in language.app.nav.navList" @click="goPage(item)">
                  <a @mouseenter="handleNavMouse('m',item)" @mouseleave="handleNavMouse('l',item)" :class="{active:componentName == item.key ||mouseName == item.key }">{{ item.name }}</a>
                </li>
              </ul>
              <div @click="handleChangeLanguage" class="language">
                <span>{{this.language.app.nav.language}}</span>
              </div>
            </div>
          </div>
      </transition>
    </nav>
  </div>
  <router-view></router-view>
  <div class="foot" id="foot">
    <div class="foot-left">
      <img src="../src/assets/images/home/logoW.svg">
      <p style="font-size: 1.4rem;margin: 0.6rem 0 10rem;">{{ language.app.common.foot.footLeft.one  }}</p>
      <p style="font-size: 1.8rem;color: #A6A6A6">{{ language.app.common.foot.footLeft.two }}</p>
      <p style="font-size: 2.4rem;margin-top: 1rem;">{{ language.app.common.foot.footLeft.three }}</p>
      <p style="font-size: 1.4rem;margin-top: 1rem;color: #A6A6A6">{{ language.app.common.foot.footLeft.four }}</p>
    </div>
    <div class="foot-mid">
      <div>
        <div v-for="(item,index) in language.app.common.footerCodeList">
          <img :class="{opacityImg:footerCodeActive != index && footerCodeActive  != 3}" @mouseenter="footerCodeActive = index" @mouseleave="footerCodeActive=3" :src="item.img">
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="foot-right">
      <p @click="goPage(item)" v-for="item in language.app.common.foot.footRightTextList">{{ item.name }}</p>
    </div>
    <p class="copy-right">{{language.app.common.foot.copyRight}}
    </p>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
import "../src/assets/css/pages/nav.less"
import "../src/assets/css/pages/foot.less"
import {useLanguageStore} from '@/store/languageStore'
import {chinese} from "@/utils/language";
export default {
  name: 'App',
  data(){
    return {
      store:{},
      language:{},
      componentName:'home',
      mouseName:'',
      active:true,
      footerCodeActive:3,
      mouseMoveTime:new Date().getTime(),
      isNavMenuShow:false
    }
  },
  beforeUnmount() {
    //组件销毁前要解绑事件
    eventBus.all.delete("navHide");
    eventBus.all.delete("navName");
  },
  beforeMount() {
    this.getLanguage()
    chinese.app.home.banner.bottomText = window.innerWidth > 750 ? '鼠标滑动查看更多' : '向上滑动查看更多'
  },
  mounted() {
    this.scrollFunc()
    // this.$router.push({name:"home"})
    eventBus.on('navHide',()=> {
      this.active = true
    })
    eventBus.on('navName',(name)=> {
      this.componentName = name
    })
    window.addEventListener('resize',()=>{
      chinese.app.home.banner.bottomText = window.innerWidth > 750 ? '鼠标滑动查看更多' : '向上滑动查看更多'
    })
  },
  watch:{
    active:{
      handler(newV){
        if(this.active){
          let timer = null
          let time = 0
          timer = setInterval(()=>{
            time++
            if(time == 3){
              clearTimeout(timer)
              this.active = false
            }
          },1000)
        }
      },
      immediate:true
    },
    isNavMenuShow(newV){
      document.body.style.overflow = newV ? 'hidden' : ''
    }
  },
  methods:{
    goPage(item){
      this.componentName = item.key
      if(item.key == 'about'){
        this.$router.push({name: 'about',params: {tabName: item.tabName ? item.tabName : 'companyProfile'}})
      }else{
        this.$router.push({name:item.key})
      }
      // if(item.key=='home'){
      //
      // }
    },
    navHide(val){
      this.active = val
    },
    scrollFunc() {
      window.addEventListener("wheel", e=>{
        let timer = null
        let time = 0
        var e = e || window.event;
        if(e.wheelDelta) {
          if(e.wheelDelta > 0) {     //当鼠标滚轮向上滚动时
            this.active = true
            timer = setInterval(()=>{
              time++
              if(time == 3){
                clearTimeout(timer)
                this.active = false
              }
            },1000)
          }
          if(e.wheelDelta < 0) {     //当鼠标滚轮向下滚动时
            this.active = false
            clearTimeout(timer)
          }
        } else if(e.detail) {
          if(e.detail < 0) {   //当鼠标滚轮向上滚动时
            this.active = true
            timer = setInterval(()=>{
              time++
              if(time == 3){
                clearTimeout(timer)
                this.active = false
              }
            },1000)
          }
          if(e.detail > 0) {   //当鼠标滚轮向下滚动时
            this.active = false
            clearTimeout(timer)
          }
        }
      })
    },
    handleNavMouse(type,item){
      this.mouseName = type == 'm' ? item.key : ''
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    },
    handleChangeLanguage(){
      let param = this.language.app.nav.language == 'EN' ? this.language.app.nav.language : 'chinese'
      this.store.setLanguage(param)
    }
  }
}
</script>

<style>
#app{
  height: 100%;
  /*min-width: 130rem;*/
}
#back {display: none}
.fp-right {display: none}
@media screen and (min-width:750px) {
  .fp-right{display: block;}
  #back{display: block}
}
</style>
