<template>
  <div class="companyProfile">
    <div>
      <div>
        <p>{{ language.app.about.companyProfile.title }}</p>
        <p>{{ language.app.about.companyProfile.content }}</p>
      </div>
      <img src="../../../assets/images/about/aboutZhizhu@2x.png" alt="">
    </div>
  </div>
</template>

<script>
import {useLanguageStore} from "@/store/languageStore";

export default {
  name: 'companyProfile',
  data(){
    return{
      store:{},
      language:{},
    }
  },
  beforeMount() {
    this.getLanguage()
  },
  methods:{
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  }
}
</script>

<style>
</style>