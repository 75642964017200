<template>
  <div class="contactUs">
    <div>
      <p>{{ language.app.about.contactUs.title }}</p>
      <div class="left">
        <div @click="setActiveCity(item)" :class="{'active':item.city ?  item.province + item.city == isCityActive:item.province == isCityActive}"  v-for="(item,index) in language.app.about.contactUs.left.addressInfo">
          <p>{{ item.province }}{{ item.city ? '·' + item.city : '' }}</p>
          <p style="margin-top: .5rem"><span style="color: #8F8F8F">{{ language.app.about.contactUs.left.type[0] }}：</span>{{ item.phone }}</p>
          <p style="margin-top: .5rem"><span style="color: #8F8F8F">{{ language.app.about.contactUs.left.type[1] }}：</span>{{ item.address }}</p>
        </div>
      </div>
      <div class="right">
        <img src="../../../assets/images/about/lian_xi_wo_men-map@2x.png" alt="">
        <div @click="setActiveCity(item)" :class="{'active' : item.city ?  item.province + item.city == isCityActive:item.province == isCityActive}" v-for="item in language.app.about.contactUs.right.mapInfo" >
          <img :src="item.image" alt="" :class="{'active' : item.city ? item.province + item.city == isCityActive:item.province == isCityActive}">
          <p>{{ item.province }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useLanguageStore} from "@/store/languageStore";
export default {
  name: 'contactUs',
  data() {
    return {
      isCityActive: '',
      store:{},
      language:{},
    }
  },
  beforeMount() {
    this.getLanguage()
    this.isCityActive = this.language.app.about.contactUs.left.addressInfo[0].province
  },
  methods: {
    setActiveCity(item) {
      this.language.app.about.contactUs.right.mapInfo[2].city = item.city ?  item.city : this.language.app.about.contactUs.left.addressInfo[2].city
      this.isCityActive = item.province +  (item.city ?  item.city : '')
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  }
}
</script>

<style>

</style>