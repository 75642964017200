<template>
  <div class="scroll-picker" :class="{ active: visible }">
    <div class="picker-mask" @click="hide"></div>
    <div class="picker-container">
      <div class="picker-header">
        <span class="picker-cancel" @click="hide">{{ buttonText[0] }}</span>
        <span class="picker-title">{{ title }}</span>
        <span class="picker-confirm" @click="confirm">{{ buttonText[1] }}</span>
      </div>
      <div class="picker-body">
        <div class="picker-column">
          <ul class="picker-items" ref="column1">
            <li v-for="(item, index) in items1" :key="index" :class="{ active: index === active1 }" @click="selectColumn1(index)">{{ item }}</li>
          </ul>
        </div>
        <div class="picker-column">
          <ul class="picker-items" ref="column2">
            <li v-for="(item, index) in items2" :key="index" :class="{ active: index === active2 }" @click="selectColumn2(index)">{{ item.name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '请选择'
    },
    list: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    buttonText:{
      type:Array,
      required: true
    }
  },
  data() {
    return {
      active1: 0,
      active2: null,
      items1: [],
      items2: []
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    visible(val) {
      if (val) {this.show()} else {this.hide()}
    }
  },
  methods: {
    init() {
      this.items1 = this.list.map(item => item.type)
      this.items2 = this.list[0].children
    },
    show() {
      document.body.style.overflow = 'hidden'
      this.$nextTick(() => {
        this.$el.classList.add('active')
        this.animateIn()
      })
    },
    hide() {
      this.animateOut(() => {
        this.$el.classList.remove('active')
        document.body.style.overflow = ''
        this.$emit('changeShow', false)
      })
    },
    animateIn() {
      let columns = this.$refs.column1.parentElement.parentElement.children
      columns = [columns[0].children[0],columns[1].children[0]]
      const duration = 300
      const delay = 100
      const translateY = columns[0].offsetHeight
      columns[0].style.transition = `transform ${duration}ms ease-out ${delay}ms`
      columns[1].style.transition = `transform ${duration}ms ease-out ${delay * 2}ms`
      columns[0].style.transform = `translateY(0)`
      columns[1].style.transform = `translateY(0)`
    },
    animateOut(callback) {
      let columns = this.$refs.column1.parentElement.parentElement.children
      columns = [columns[0].children[0],columns[1].children[0]]
      const duration = 100
      const delay = 100
      const translateY = columns[0].offsetHeight
      columns[0].style.transition = `transform ${duration}ms ease-out`
      columns[1].style.transition = `transform ${duration}ms ease-out`
      columns[0].style.transform = `translateY(${translateY}px)`
      columns[1].style.transform = `translateY(${translateY}px)`
      setTimeout(callback, duration + delay)
    },
    selectColumn1(index) {
      this.active1 = index
      this.items2 = this.list[index].children
      this.active2 = null
    },
    selectColumn2(index) {
      this.active2 = index
    },
    confirm() {
      this.$emit('select', { item1:this.active1 ,item2:this.active2 })
      this.hide()
    }
  }
}
</script>

<style scoped lang="less">
.scroll-picker {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
}

.scroll-picker.active {
  opacity: 1;
  visibility: visible;
}

.picker-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.picker-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}

.scroll-picker.active .picker-container {
  transform: translateY(0);
}

.picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 20px;
  font-size: 13px;
  color: #333;
  background-color: #f5f5f5;
}

.picker-title {
  flex: 1;
  text-align: center;
}

.picker-cancel,
.picker-confirm {
  cursor: pointer;
}

.picker-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 220px;
  padding: 20px;
}

.picker-column {
  padding: 2px 0;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.picker-items {
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  color: #666;
}

.picker-items li {
  height: 34px;
  line-height: 34px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 0.3s ease-out;
}

.picker-items li.active {
  color: #1677ff;
  font-size: 14px;
}
</style>