<template>
  <div class="jobsDetail">
    <div id="back" class="back" @click="$router.back"><img src="../../assets/images/goBack.svg" alt="">{{ language.app.common.jobAndNewDetailBtn }}</div>
    <p class="title">{{ content.name }}</p>
    <p class="content">{{ content.tag }}</p>
    <div class="tip">
      <div>{{ language.app.about.talentAcquisition.jobs.jobDetail.jobDescription }}</div>
      <div>
        <div v-for="item in this.content.descriptions.tips" class="recruitDetailContent-tag">
          {{item}}
        </div>
      </div>
    </div>
    <div class="body">
      <p class="title">{{ language.app.about.talentAcquisition.jobs.jobDetail.bodyTitle1 }}</p>
      <p class="text" v-html="content.descriptions.duty"></p>
      <p class="title">{{ language.app.about.talentAcquisition.jobs.jobDetail.bodyTitle2 }}</p>
      <p class="text" v-html="content.descriptions.require"></p>
      <p class="title">{{ language.app.about.talentAcquisition.jobs.jobDetail.bodyTitle3 }}</p>
      <p class="text" v-html="content.descriptions.address"></p>
      <p class="title">{{ language.app.about.talentAcquisition.jobs.jobDetail.bodyTitle4 }}</p>
      <p class="text" v-html="content.descriptions.contact"></p>
    </div>
  </div>
</template>

<script>
import {useLanguageStore} from "@/store/languageStore";

export default {
  name: "jobsDetail",
  data() {
    return {
      content: {
        descriptions: {
          tips: []
        }
      },
      store:{},
      language:{},
    }
  },
  beforeMount() {
    this.getLanguage()
  },
  mounted() {
    this.content = JSON.parse(sessionStorage.getItem('params'))
  },
  methods:{
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    }
  }
}
</script>

<style lang="less">
.jobsDetail {
  //max-width: 1000px;
  margin: 7.5rem 5% 0;
  .back {
    display: flex;
    color: #424242;
    font-size: 1.8rem;
    cursor: pointer;
    opacity: .5;
    img {
      width: .8rem;
      margin-right: 1rem;
    }
  }
  .title {
    font-size: 1.8rem;
    color: #272727;
    margin: 1rem 0 1rem;
    font-weight: 600;
  }
  .content {
    color: #A8A8A8;
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  .tip {
    font-size: 1.4rem;
    display: inline-block;
    > div{
      &:first-child{
        display: block;
        position: relative;
        padding-left: 1rem;
        margin-bottom: 1rem;
        &::before{
          content: "";
          height: 60%;
          width: .5rem;
          background: #2E6FFE;
          border-radius: .1rem;
          position: absolute;
          top: 20%;
          left: 0;
        }
      }
      &:last-child{
        display: flex;
        flex-wrap: wrap;
        div{
          font-size: 1.2rem;
          padding: .5rem .9rem;
          background-color: #F3F3F3;
          border-radius: .6rem;
          margin: 0 1rem .5rem 0 ;
        }
      }
    }
  }

  .body {
    margin-top: 3rem;
    padding-bottom: 3rem;
    .title, .text {
      color: #272727;
      font-size: 1.6rem;
      margin-bottom: .5rem;
      line-height: 2rem;
    }
    .text {
      color: #757575;
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
  }
}
@media screen and (min-width:750px) {
  .jobsDetail{
    margin: 12rem 0 5rem 10%;
    .title {
      font-size: 3rem;
      margin: 3rem 0 1rem;
      font-weight: normal;
    }
    .content {
      color: #4A4A4A;
      font-size: 2rem;
      margin-bottom: 5rem;
    }
    .tip {
      font-size: 2rem;
      > div{
        &:first-child{
          &::before{
            width: .2rem;
          }
        }
        &:last-child{
          div{
            font-size: 1.4rem;
            margin-right: 1.5rem;
          }
        }
      }
    }
    .body{
      margin-top: 5rem;
      padding-bottom: 5rem;
      .title, .text {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        line-height: 4rem;
      }
      .text {
        font-size: 1.6rem;
        margin-bottom: 3rem;
      }
    }
  }
}
</style>