<template>
  <div class="page-home">
    <full-page :options="options" id="fullPage" ref="fullPage">
      <div class="scroll">
        <div class="banner">
          <div class="banner-content">
            <video muted autoplay="autoplay" loop="loop" data-autoplay>
              <source :src="bannerVideo" type="video/mp4"/>
            </video>
            <p>{{ language.app.home.banner.centerText }}</p>
            <span>
              <img src="@/assets/images/mouse.svg" alt="">
              <span style="opacity: .6">{{ language.app.home.banner.bottomText }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="scroll">
        <div class="body-content1">
          <div>
            <div>
              <p>{{ language.app.home.bodyContent1.title }}</p>
              <div>
                <div v-for="(item,index) in language.app.home.bodyContent1.rowOne" :style="{padding:index=='1'?'0 10rem':'',minWidth:index=='2'? '33rem': '20rem'}">
                  <p>
                    <countTo :style="{width:item.width}"  :startVal='0' :endVal='item.num' :duration='6000'></countTo>
                    <span>{{ item.unit }}</span>
                  </p>
                  <p>{{ item.name }}</p>
                </div>
              </div>
              <div>
                <div v-for="item in language.app.home.bodyContent1.rowTwo">
                  <p>
                    <countTo :style="{width:item.width}"  :startVal='0' :endVal='item.num' :duration='6000'></countTo>
                    <span>{{ item.unit }}</span>
                  </p>
                  <p>{{ item.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <video muted autoplay="autoplay" loop="loop" data-autoplay>
            <source :src="bodyVideo" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="scroll">
        <div class="body-content2">
          <div>
            <div>
              <p>
                {{ language.app.home.bodyContent2.title }}
              </p>
              <div style="margin-top: 15%">
                <div style="margin-right: 27%">
                  <p @click="goPage('#a')">
                    <img src="../assets/images/home/ecologySystem/xingyun.png" alt="">
                  </p>
                  <div class="box-left cex" style="top: -14rem">
                    <div>
                    </div>
                    {{ language.app.home.bodyContent2.content[0] }}
                  </div>
                </div>
                <div style="margin-left: 15rem">
                  <p @click="goPage('#b')">
                    <img src="../assets/images/home/ecologySystem/zhizhu.png" alt="">
                  </p>
                  <div class="box-right cex" style="top: -12rem">
                    <div>
                    </div>
                    {{ language.app.home.bodyContent2.content[1] }}
                  </div>
                </div>
              </div>
              <div style="margin-top: 16%;margin-bottom: 10%">
                <div style="margin-left: 8rem;margin-right: 7rem">
                  <p @click="goPage('#c')">
                    <img src="../assets/images/home/ecologySystem/shuzi.png" alt="">
                  </p>
                  <div class="box-left cex" style="top: -14rem;left: -46rem">
                    <div class="up">
                    </div>
                    {{ language.app.home.bodyContent2.content[2] }}
                  </div>
                </div>
                <div style="margin-right: 8rem;margin-left: 7rem">
                  <div class="semicircle-up">
                  </div>
                  <p @click="goPage('#d')">
                    <img src="../assets/images/home/ecologySystem/zengzhi.png" alt="">
                  </p>
                  <div class="semicircle">
                    <div class="circle">
                    </div>
                    <div class="sq">
                      <ul>
                        <li @click="goPageD(language.app.home.bodyContent2.content[3][0],0)"><span>{{ language.app.home.bodyContent2.content[3][0] }}</span></li>
                        <li @click="goPageD(language.app.home.bodyContent2.content[3][1],1)" style="transform: translateX(-1.5rem);"><span>{{ language.app.home.bodyContent2.content[3][1] }}</span></li>
                        <li @click="goPageD(language.app.home.bodyContent2.content[3][2],2)" style="transform: translateX(-2.5rem);"><span>{{ language.app.home.bodyContent2.content[3][2] }}</span></li>
                        <li @click="goPageD(language.app.home.bodyContent2.content[3][3],3)" style="transform: translateX(-3rem);"><span>{{ language.app.home.bodyContent2.content[3][3] }}</span></li>
                        <li @click="goPageD(language.app.home.bodyContent2.content[3][4],4)" style="transform: translateX(-3rem);"><span>{{ language.app.home.bodyContent2.content[3][4] }}</span></li>
                        <li @click="goPageD(language.app.home.bodyContent2.content[3][5],5)" style="transform: translateX(-2.5rem);"><span>{{ language.app.home.bodyContent2.content[3][5] }}</span></li>
                        <li @click="goPageD(language.app.home.bodyContent2.content[3][6],6)" style="transform: translateX(-1.5rem);"><span>{{ language.app.home.bodyContent2.content[3][6] }}</span></li>
                        <li @click="goPageD(language.app.home.bodyContent2.content[3][7],7)" ><span>{{ language.app.home.bodyContent2.content[3][7] }}</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="../assets/images/home/content2@2x.png">
        </div>
      </div>
      <div class="scroll">
        <div class="body-content3">
          <div>
            <div>
              <p>
                {{ language.app.home.bodyContent3.title }}
              </p>
              <div class="cooperate">
                <div>
                  <div style="animation-duration: 20s;">
                    <div v-for="item in companyLogo1">
                      <img :src="require('../assets/images/home/companyLogo/'+item+'.png')">
                    </div>
                  </div>
                  <div style="animation-duration: 20s;">
                    <div v-for="item in companyLogo1">
                      <img :src="require('../assets/images/home/companyLogo/'+item+'.png')">
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div v-for="item in companyLogo2">
                      <img :src="require('../assets/images/home/companyLogo/'+item+'.png')">
                    </div>
                  </div>
                  <div>
                    <div v-for="item in companyLogo2">
                      <img :src="require('../assets/images/home/companyLogo/'+item+'.png')">
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div v-for="item in companyLogo3">
                      <img :src="require('../assets/images/home/companyLogo/'+item+'.png')">
                    </div>
                  </div>
                  <div>
                    <div v-for="item in companyLogo3">
                      <img :src="require('../assets/images/home/companyLogo/'+item+'.png')">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll">
        <div class="body-content4">
          <div>
            <div>
              <p>
                {{ language.app.common.news.title }}
              </p>
              <div class="news">
                <div class="before" @click="turn('left')">
                  <img src="../assets/images/page/pre.svg">
                </div>
                <div class="content">
                  <div :style="{transform: 'translateX('+turnNumb+'%)'}">
                    <div  @click="newsDetail(item,index)" v-for="item in newsList">
                      <div class="img-box">
                        <div>
                          <div  @click="newsDetail(item,index)">
                            {{ language.app.common.news.newsItemBtn }}
                          </div>
                        </div>
                        <img :src="item.coverImageUrl">
                      </div>
                      <div class="title-box">
                        <p>{{ item.title }}</p>
                        <p>{{ item.createTime }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="next" @click="turn('right')">
                  <img src="../assets/images/page/next.svg">
                </div>
              </div>
              <div class="news-more-btn">
                <div @click="newTrends">{{ language.app.common.news.moreNews }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <div class="foot-left">
            <img src="../assets/images/home/logoW.svg">
            <p style="font-size: 1.4rem;margin-top: 0.6rem;">{{ this.language.app.common.foot.footLeft.one }}</p>
            <p style="font-size: 1.8rem;margin-top: 10rem;color: #A6A6A6">{{ this.language.app.common.foot.footLeft.two }}</p>
            <p style="font-size: 2.4rem;margin-top: 1rem;">{{ this.language.app.common.foot.footLeft.three }}</p>
            <p style="font-size: 1.4rem;margin-top: 1rem;color: #A6A6A6">{{ this.language.app.common.foot.footLeft.four }}</p>
          </div>
          <div class="foot-mid">
            <div>
              <div v-for="(item,index) in this.language.app.common.footerCodeList">
                <img :class="{opacityImg:(footerCodeActive != index && footerCodeActive  != 3)}" @mouseenter="footerCodeActive = index" @mouseleave="footerCodeActive=3" :src="item.img">
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div class="foot-right">
            <p @click="goToPage(item)" v-for="item in this.language.app.common.foot.footRightTextList">{{ item.name }}</p>
          </div>
          <p class="copy-right">{{ this.language.app.common.foot.copyRight_1 }}<span><a href="https://beian.miit.gov.cn/" target="_blank" style="color: #a6a6a6;font-size: 1.4rem;text-decoration: none">{{ this.language.app.common.foot.copyRight_2 }}</a></span></p>
        </div>
      </div>
    </full-page>
    <div class="mlHome">
      <div class="banner-ml">
        <div class="banner-content">
          <video id="bannerVideo" x5-video-player-type="h5"
                 x5-video-player-fullscreen="false"
                 playsinline
                 webkit-playsinline loop muted :src="bannerVideoMl" data-autoplay autoplay type="video/mp4">
<!--            <source type="video/mp4"/>-->
          </video>
          <p>{{ language.app.home.banner.centerText }}</p>
          <span>
<!--            <img src="@/assets/images/mouse.svg" alt="">-->
            <span style="opacity: .6">{{ language.app.home.banner.bottomText }}</span>
          </span>
        </div>
      </div>
      <div class="body-content1-ml">
        <div>
          <div>
            <p>{{ language.app.home.bodyContent1.title }}</p>
            <div>
              <div v-for="(item,index) in language.app.home.bodyContent1.rowOne">
                <p>
                  <countTo :startVal='0' :endVal='item.num' :duration='6000'></countTo>
                  <span>{{ item.unit }}</span>
                </p>
                <p>{{ item.name }}</p>
              </div>
            </div>
            <div>
              <div v-for="item in language.app.home.bodyContent1.rowTwo">
                <p>
                  <countTo  :startVal='0' :endVal='item.num' :duration='6000'></countTo>
                  <span>{{ item.unit }}</span>
                </p>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <video id="bodyVideo" x5-video-player-type="h5"
               x5-video-player-fullscreen="false"
               playsinline
               webkit-playsinline loop muted :src="bodyVideoMl" data-autoplay autoplay type="video/mp4">
<!--          <source  type="video/mp4" />-->
        </video>
      </div>
      <div class="body-content2-ml">
        <div>
          <div>
            <p>
              {{ language.app.home.bodyContent2.title }}
            </p>
            <div style="margin-top: -5%;margin-left: 14%">
              <div style="margin-right: 10%">
                <p @click="goPage('#mlA')">
                  <img src="../assets/images/home/ecologySystem/xingyun.png" alt="">
                </p>
              </div>
              <div style="margin-left: 11rem">
                <p @click="goPage('#mlB')"><img src="../assets/images/home/ecologySystem/zhizhu.png" alt=""></p>
              </div>
            </div>
            <div style="margin-top: 16%;margin-bottom: 10%">
              <div style="margin-left: 8rem;margin-right: -2rem">
                <p @click="goPage('#mlC')"><img src="../assets/images/home/ecologySystem/shuzi.png" alt=""></p>
              </div>
              <div style="margin-right: 8rem;margin-left: 7rem">
                <div class="semicircle-up">
                </div>
                <p @click="goPage('#mlD')"><img src="../assets/images/home/ecologySystem/zengzhi.png" alt=""></p>
              </div>
            </div>
          </div>
        </div>
        <img src="../assets/images/home/content2@2x.png">
      </div>
      <div class="body-content3-ml">
        <div>
          <div>
            <p>{{ language.app.home.bodyContent3.title }}</p>
            <div class="cooperate">
              <div>
                <div style="animation-duration: 20s;">
                  <div v-for="item in companyLogo1"><img :src="require('../assets/images/home/companyLogo/'+item+'.png')"></div>
                </div>
                <div style="animation-duration: 20s;">
                  <div v-for="item in companyLogo1"><img :src="require('../assets/images/home/companyLogo/'+item+'.png')"></div>
                </div>
              </div>
              <div>
                <div>
                  <div v-for="item in companyLogo2"><img :src="require('../assets/images/home/companyLogo/'+item+'.png')"></div>
                </div>
                <div>
                  <div v-for="item in companyLogo2"><img :src="require('../assets/images/home/companyLogo/'+item+'.png')"></div>
                </div>
              </div>
              <div>
                <div>
                  <div v-for="item in companyLogo3"><img :src="require('../assets/images/home/companyLogo/'+item+'.png')"></div>
                </div>
                <div>
                  <div v-for="item in companyLogo3"><img :src="require('../assets/images/home/companyLogo/'+item+'.png')"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-content4-ml">
        <div>
          <div>
            <p>{{ language.app.common.news.title }}</p>
            <div class="news">
              <div class="before" @click="turn('left')"><img src="../assets/images/page/pre.svg"></div>
              <div class="content hideScrollLine">
                <div :style="{transform: 'translateX('+turnNumb+'%)'}">
                  <div  @click="newsDetail(item,index)" v-for="item in newsList">
                    <div class="img-box">
                      <div>
                        <div  @click="newsDetail(item,index)">{{ language.app.common.news.newsItemBtn }}</div>
                      </div>
                      <img :src="item.coverImageUrl">
                    </div>
                    <div class="title-box">
                      <p>{{ item.title }}</p>
                      <p>{{ item.createTime }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="next" @click="turn('right')"><img src="../assets/images/page/next.svg"></div>
            </div>
            <div class="news-more-btn"><div @click="newTrends">{{ language.app.common.news.moreNews }}</div></div>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="foot-left">
          <img src="../../src/assets/images/home/logoW.svg"/>
          <p style="font-size: 1.4rem;margin: 0.6rem 0 10rem;">{{ this.language.app.common.foot.footLeft.one }}</p>
          <p style="font-size: 1.8rem;color: #A6A6A6">{{ this.language.app.common.foot.footLeft.two }}</p>
          <p style="font-size: 2.4rem;margin-top: 1rem;">{{ this.language.app.common.foot.footLeft.three }}</p>
          <p style="font-size: 1.4rem;margin-top: 1rem;color: #A6A6A6">{{ this.language.app.common.foot.footLeft.four }}</p>
        </div>
        <div class="foot-mid">
          <div>
            <div v-for="(item,index) in language.app.common.footerCodeList">
              <img :class="{opacityImg:footerCodeActive != index && footerCodeActive  != 3}" @mouseenter="footerCodeActive = index" @mouseleave="footerCodeActive=3" :src="item.img">
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="foot-right"><p @click="goToPage(item)" v-for="item in language.app.common.foot.footRightTextList">{{ item.name }}</p></div>
        <p class="copy-right">{{ this.language.app.common.foot.copyRight }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
import "../assets/css/pages/home.less"
import "../assets/css/pages/foot.less"
import {getNewsPage} from "@/views/apiJs/homeApi";
import {useLanguageStore} from "@/store/languageStore";
import bannerV from '@/assets/mp4/1.mp4'
import bodyV from '@/assets/mp4/2.mp4'

export default {
  name: "home",
  data(){
    return{
      options: {
        //sectionsColor: ["#f00","#0f0","#00f"],        // 为每个section设置背景色
        //controlArrows: false,        //用来控制slide幻灯片的箭头，设置为false，两侧的箭头会消失
        //verticalCentered: false,        //每一页幻灯片的内容是否垂直居中
        //resize: true,        //字体是否随着窗口缩放而缩放
        //scrollingSpeed: 700,        //页面滚动速度
        //anchors: ["page1","page2","page3"],        //定义锚链接，用户可以快速打开定位到某一页面；不需要加"#"，不要和页面中任意的id和name相同
        //lockAnchors: true,        //是否锁定锚链接
        //easing:,        //定义section页面的滚动方式，需要引入jquery.easings插件
        //css3: false,        //是否使用css3 transform来实现滚动效果
        //loopTop: true,        //滚动到最顶部后是否连续滚动到底部
        //loopBottom: true,        //滚动到最底部后是否连续滚动到顶部
        //loopHorizontal: false,        //横向slide幻灯片是否循环滚动
        //continuousVertical: true,        //是否循环滚动，不会出现跳动，效果很平滑
        autoScrolling: true,        //是否使用插件滚动方式，设为false后，会出现浏览器自带的滚动条，将不会按页滚动
        //scrollBar: true,        //是否包含滚动条，设为true，则浏览器自带的滚动条会出现，页面还是按页滚动，但是浏览器滚动条默认行为也有效
        //paddingTop: "100px",        //设置每个section顶部的padding，当我们要设置一个固定在顶部的菜单、导航、元素等时使用
        //paddingBottom: "100px",        //设置每个section底部的padding，当我们要设置一个固定在底部的菜单、导航、元素等时使用
        //fixedElements: ".nav",        //固定的元素，为jquery选择器；可用于顶部导航等
        //keyboardScrolling: false,        //是否可以使用键盘方向键导航
        //touchSensitivity: 5,        //在移动设置中页面敏感性，最大为100，越大越难滑动
        //animateAnchor: false,        //设为false，则通过锚链接定位到某个页面不再有动画效果
        //recordHistory: true,        //是否记录历史，可以通过浏览器的前进后退来导航
        //menu: '.nav',        //绑定菜单，设定相关属性和anchors的值对应后，菜单可以控制幻灯片滚动
        //navigation: true,        //是否显示导航，设为true会显示小圆点作为导航
        //navigationPosition: right,        //导航小圆点的位置，可以设置为left或者right
        //navigationTooltips: ["第一页","第二页","第三页"],        //鼠标移动到小圆点上时显示出的提示信息
        //showActiveTooltip: true,        //是否显示当前页面小圆点导航的提示信息，不需要鼠标移上
        //slidesNavigation: true,        //是否显示横向幻灯片的导航
        //slidesNavPosition: bottom,        //横向幻灯片导航的位置，可以为top或者bottom
        //scrollOverflow: true,        //内容超过满屏时是否显示滚动条，需要jquery.slimscroll插件
        sectionSelector: ".scroll",        //section选择器
        //slideSelector: ".slide"        //slide选择器
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        navigation: true, //是否显示导航，默认为false
        navigationPosition: 'right', //导航小圆点的位置
        scrollBar: false,
        keyboardScrolling: false, //是否可以使用键盘方向键导航，默认为true
        continuousVertical: false, /// 是否循环滚动，默认为false。如果设置为true，则页面会循环滚动，而不像loopTop或loopBottom那样出现跳动，注意这个属性和loopTop、loopBottom不兼容和，不要同时设置
        menu: '#menu',
        onLeave: (index, nextIndex, direction)=>{
          this.numDataCall(nextIndex.index)
        }
      },
      companyLogo1:[1,2,3,4,5,6,7],
      companyLogo2:[8,9,10,11,12,13,14],
      companyLogo3:[15,16,17,18,19,20,21,22],
      newsList:[],
      turnNumb:0,
      footerCodeActive:3,
      bannerVideo:'',
      bodyVideo:'',
      bannerVideoMl:'',
      bodyVideoMl:'',
      store:{},
      language:{}
    };
  },
  beforeMount() {
    this.getLanguage()
  },
  mounted(){
    if(window.innerWidth <= 750){
      this.bannerVideoMl = bannerV
      this.bodyVideoMl = bodyV
      this.$nextTick((e) => {
        const video = document.getElementById('bannerVideo')
        const video1 = document.getElementById('bodyVideo')
        // 一般情况下，这样就可以自动播放了，但是一些奇葩iPhone机不可以
        setTimeout(()=>{
          video.play()
          video1.play()
        },2000)
        // 必须在微信Weixin JSAPI的WeixinJSBridgeReady才能生效
        document.addEventListener('WeixinJSBridgeReady', () => {
          video.play()
          video1.play()
        },false)
        document.body.addEventListener('click', () => {
          video.play()
          video1.play()
        })
      })
      this.language.app.home.bodyContent1.rowOne[0].num = 300
      this.language.app.home.bodyContent1.rowOne[1].num = 3
      this.language.app.home.bodyContent1.rowOne[2].num = 20000
      this.language.app.home.bodyContent1.rowTwo[0].num = 220
      this.language.app.home.bodyContent1.rowTwo[1].num = 60
    }else {
      this.bannerVideo = bannerV
      this.bodyVideo = bodyV
      fullpage_api.moveTo(1)
    }
    document.onkeydown = (event)=>{
      if(event.keyCode == '17' || event.keyCode == '91'){
        this.options.scrollBar = true
        this.options.autoScrolling = false
      }
    }
    document.onkeyup = (event)=>{
      this.options.scrollBar = false
      this.options.autoScrolling = true
    }
    getNewsPage({currentPage:1,pageSize:8}).then(res=>{
      this.newsList = res.data.records
    })
    document.getElementById('foot').style.display="none";
  },
  methods:{
    numDataCall(index){
      if(window.innerWidth > 750){
        if(index != '0' && index != '1' && index != '2' ){
          document.body.classList.remove('fullMark')
        }else{
          document.body.classList.add("fullMark")
        }
        if(index == '1'){
          this.language.app.home.bodyContent1.rowOne[0].num = 300
          this.language.app.home.bodyContent1.rowOne[1].num = 3
          this.language.app.home.bodyContent1.rowOne[2].num = 20000
          this.language.app.home.bodyContent1.rowTwo[0].num = 220
          this.language.app.home.bodyContent1.rowTwo[1].num = 60
        }else{
          setTimeout(()=>{
            this.language.app.home.bodyContent1.rowOne.map(item=>{
              item.num = 0
            })
            this.language.app.home.bodyContent1.rowTwo.map(item=>{
              item.num = 0
            })
          },200)
        }
      }
    },
    goPage(id){
      this.$router.push({name: 'product'})
      eventBus.emit('navName','product')
      setTimeout(()=>{
        eventBus.emit('scrollTo',id)
      },200)
    },
    goPageD(nameFlag,index){
      this.$router.push({name: 'product'})
      eventBus.emit('navName','product')
      setTimeout(()=>{
        eventBus.emit('scrollTo',"#d")
        eventBus.emit('tabTo',{nameFlag:nameFlag,index:index})
      },200)
    },
    newsDetail(item) {
      this.$router.push({name: 'newsDetail', params: {id: item.websiteNewsId}})
    },
    newTrends(){
      this.$router.push({name: 'about',params: {tabName: "newTrends"}})
      eventBus.emit('navName','about')
    },
    turn(type){
      if(type == 'right'){
        this.turnNumb = this.turnNumb - 30
        if( this.turnNumb < -100){
          this.turnNumb = -100
        }
      }else{
        this.turnNumb = this.turnNumb + 30
        if( this.turnNumb > 0){
          this.turnNumb = 0
        }
      }
    },
    goToPage(item){
      if(item.key == 'about'){
        this.$router.push({name: 'about',params: {tabName: item.tabName ? item.tabName : 'companyProfile'}})
        eventBus.emit('navName','about')
      }else{
        this.$router.push({name:item.key})
        eventBus.emit('navName','product')
      }
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    },
  }
}
</script>

<style>
.fp-overflow{
  height: 100%;
}
.fp-watermark{
  display: none;
}
.fullMark #fp-nav ul li a span, .fp-slidesNav ul li a span{
  background: #fff;
  opacity: .7;
}
</style>