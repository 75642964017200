<template>
  <div class="jobsList">
    <div class="banner">
      <img src="../../assets/images/jobs/banner@2x.png" alt="">
    </div>
    <div class="jobsList-content">
      <div>
        <div class="left">
          <p>{{ language.app.about.talentAcquisition.jobs.leftTitle }}</p>
          <div v-for="(item,index) in jobsList" :class="{active:activeIndex == index}">
            <p style="margin: 1.6rem 0" @click="listDrop(index,item)">{{ item.type }}</p>
            <div style="clear: both"></div>
            <div :id="'height'+index">
              <span @click="listDropClick(subIndex,subItem)" :key="index" v-for="(subItem,subIndex) in item.subList" :class="{active:subActiveIndex == subIndex}">{{ subItem }}</span>
            </div>
          </div>
        </div>
        <div class="showJobsTypeBtn">
          <button @click="showPicker">{{ language.app.about.talentAcquisition.jobs.showJobsTypeBtn }} <img style="vertical-align: unset;margin-left: 10px" :src="showJobTypeIcon" alt=""></button>
        </div>
        <div class="right">
          <p >{{subItem.type}}({{subItem.total}})</p>
          <template v-for="(item,index) in subItem.children">
            <transition name="tabFade2">
              <div @click="jobsDetail(item)" v-show="subActiveName == item.name || subActiveName == ''">
                <p>{{ item.name }}</p>
                <div style="color:#F53939;margin-top: 1.6rem">{{ item.salary }}</div>
                <div style="color: #4A4A4A;">{{ item.tag }}</div>
                <div style="color: #8D8D8D;margin-top: 1rem">{{ item.description }}</div>
              </div>
            </transition>
          </template>
        </div>
      </div>
    </div>
    <picker :button-text="language.app.about.talentAcquisition.jobs.pickerBtnText" :title="pickerTitle" :list="jobsList" :visible="selectShow" @changeShow="(val)=>{this.selectShow = val}" @select="handleSelect"></picker>
  </div>
</template>

<script>
import "../../assets/css/pages/jobs.less"
import {useLanguageStore} from "@/store/languageStore";
import picker from "@/components/picker";
import down from '@/assets/images/jobs/showJobsTypeS.svg'
import up from '@/assets/images/jobs/showJobsTypeX.svg'
export default {
  name: 'jobsList',
  components: {picker},
  data() {
    return {
      activeIndex:0,
      subActiveIndex:null,
      subActiveName:'',
      subItem:{},
      height:0,
      pickerTitle: '',
      selectShow: false,
      showJobTypeIcon:down,
      // 招聘内容列表
      jobsList: [],
      store:{},
      language:{},
    }
  },
  mounted() {
    this.listDrop(0,this.jobsList[0])
    this.pickerTitle = this.language.app.about.talentAcquisition.jobs.pickerTitle
  },
  beforeMount() {
    this.getLanguage()
    this.jobsList = this.language.app.about.talentAcquisition.jobs.jobsList
  },
  watch:{
    selectShow(val){
      this.showJobTypeIcon = val ? up : down
    }
  },
  methods: {
    jobsDetail(item){
      this.$router.push({name:'jobsDetail'})
      sessionStorage.setItem("params",JSON.stringify(item))
    },
    listDrop(index,item) {
      this.subActiveIndex = null
      this.subActiveName = ''
      this.activeIndex = index
      this.subItem = item
      let height=0
      for(let i=0 ; i < document.getElementById("height"+index).children.length ; i++){
        height += document.getElementById("height"+index).children[i].clientHeight
      }
      document.getElementById("height"+index).style.maxHeight = height+'px'
      this.jobsList.map((item, indexS) => {
        if(indexS != index){
          document.getElementById("height"+indexS).style.maxHeight = 0+'px'
        }
      })
    },
    listDropClick(index,item){
      if(this.subActiveIndex != null && this.subActiveIndex == index){
        this.subActiveIndex = null
        this.subActiveName = ''
      }else{
        this.subActiveIndex = index
        this.subActiveName = item
      }
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
        this.jobsList = this.language.app.about.talentAcquisition.jobs.jobsList
        this.subItem = this.language.app.about.talentAcquisition.jobs.jobsList[0]
      })
    },
    showPicker() {
      this.selectShow = true
    },
    handleSelect({ item1, item2 }) {
      this.subItem = {}
      this.subItem.type = this.jobsList[item1].type
      this.subItem.total = this.jobsList[item1].total
      this.subItem.children = item2 == null ?  [...this.jobsList[item1].children] : [this.jobsList[item1].children[item2]]
    }
  }
}
</script>