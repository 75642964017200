<template>
  <div class="page-about">
    <div class="banner">
      <img src="../../assets/images/about/aboutTopBackground@2x.png" alt="">
      <span>
        <img src="../../assets/images/logo_white.svg" alt="" style="">
        <p>{{ language.app.about.title }}</p>
      </span>
    </div>
    <div class="nav">
      <div>
        <ul>
          <li v-for="item in language.app.about.navList" @click="tab(item.name,item.code)">
            <a :class="{active:nameFlag==item.name}">{{item.name}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <transition  name="tabFade1">
        <component :is="componentName" :text="language"></component>
      </transition>
    </div>
  </div>
</template>

<script>
import "../../assets/css/pages/about.less"
import companyProfile from "@/views/about/tabs/companyProfile";
import developmentHistory from "@/views/about/tabs/developmentHistory";
import socialResponsibility from "@/views/about/tabs/socialResponsibility";
import newTrends from "@/views/about/tabs/newTrends";
import talentAcquisition from "@/views/about/tabs/talentAcquisition";
import contactUs from "@/views/about/tabs/contactUs";
import {useLanguageStore} from "@/store/languageStore";
export default {
  name:'about',
  components:{
    companyProfile,
    developmentHistory,
    socialResponsibility,
    newTrends,
    talentAcquisition,
    contactUs
  },
  data(){
    return {
      nameFlag:'',
      componentName:"companyProfile",
      store:{},
      language:{},
    }
  },
  beforeMount() {
    this.getLanguage()
    this.nameFlag = this.language.app.about.navList[0].name
  },
  mounted() {
    if(this.$route.params.tabName && this.$route.params.tabName == 'newTrends'){
      this.componentName = "newTrends"
      this.nameFlag = this.language.app.about.navList[3].name
    }else if(this.$route.params.tabName && this.$route.params.tabName == 'contactUs'){
      this.componentName = "contactUs"
      this.nameFlag = this.language.app.about.navList[5].name
    }else{
      this.componentName = "companyProfile"
      this.nameFlag = this.language.app.about.navList[0].name
    }
  },
  methods:{
    tab(name,code){
      this.componentName = code
      this.nameFlag = name
    },
    getLanguage(){
      this.store = useLanguageStore()
      this.language = this.store.languageObj
      this.store.$subscribe((args,state)=>{
        this.language = state.languageObj
      })
    },
  },
  watch:{
    componentName(newV,oldV){
      this.language.app.about.navList.map(item=>{
        if(item.code == newV){
          this.componentName = item.code
          this.nameFlag = item.name
        }
      })
    }
  }
}
</script>

<style scoped>

</style>